import {FC, ReactElement} from "react"
import {FormControl, Grid, InputLabel, MenuItem, Select, Typography} from "@mui/material"
import BlockContainer from "../BlockContainer"
import TimeSelector, {ITimeSelector} from "../TimeSelector"
import OptionsInput, {IOptionsInput} from "../OptionsInput"
import CarClassInput, {ICarClassInput} from "../CarClassInput"
import DelayedOptionsInput, {IDelayedOptionsInput} from "../DelayedOptionsInput"

interface IAutoBlock extends ICarClassInput, ITimeSelector, IOptionsInput, IDelayedOptionsInput {
}

const AutoBlock: FC<IAutoBlock> = (props): ReactElement => {

    return (
        <>
            <BlockContainer item container>
                <Typography variant={"h5"}>Автомобиль</Typography>
            </BlockContainer>
            <BlockContainer item container spacing={2}>
                <Grid item>
                    <CarClassInput {...props} />
                </Grid>
                <Grid item>
                    <OptionsInput {...props} />
                </Grid>
            </BlockContainer>
            <BlockContainer item container>
                <TimeSelector {...props} />
            </BlockContainer>
            <BlockContainer item container>
                <DelayedOptionsInput {...props} />
            </BlockContainer>
        </>
    )
}

export default AutoBlock