import React, {FC, ReactElement} from "react"
import useNewOrder from "@kashalot-web/react/dist/useNewOrder"
import PaymentTypePanel from "../../../../LocalUICore/mobile/NewOrderPanels/PaymentTypePanel"
import PaymentTypes from "@kashalot-web/core/dist/TaxiInfo/PaymentTypes"

interface IPaymentTypePanelContainer {
    isOpen: boolean,
    open: () => void
    close: () => void
}

const PaymentTypePanelContainer: FC<IPaymentTypePanelContainer> = ({isOpen, open, close}): ReactElement => {
    const {order, editOrderDetail} = useNewOrder()

    return (
        <PaymentTypePanel
            isOpen={isOpen}
            open={open}
            close={close}
            paymentTypes={PaymentTypes.getAllPaymentTypes()}
            selectedPaymentType={order.offersManager.paymentType}
            selectPaymentType={(newPaymentType: PaymentType) => editOrderDetail("paymentType", newPaymentType )}
            setCashback={cashback => editOrderDetail("cashback", cashback )}
            selectedCashback={order.offersManager.cashback}
        />
    )
}

export default PaymentTypePanelContainer