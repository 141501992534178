import {useState} from "react"

type Opener = [boolean, () => void, () => void]

const useOpener = (defaultIsOpen: boolean): Opener => {
    const [isOpen, setIsOpen] = useState<boolean>(defaultIsOpen)
    const open = () => setIsOpen(true)
    const close = () => setIsOpen(false)

    return [isOpen, open, close]
}

export default useOpener