import React, {FC, ReactElement} from "react"
import BlockContainer, {BlockContainerWithSidePaddings} from "../BlockContainer"
import {Button, useTheme} from "@mui/material"
import {primaryDarkerTheme} from "@kashalot-web/ui/dist"
import {ThemeProvider} from "@mui/material/styles"

interface IMainButtonBlock {
    label: string
    onClick: () => void
    disabled?: boolean
}

const MainButtonBlock: FC<IMainButtonBlock> = ({label, onClick, disabled}): ReactElement => {
    const theme = useTheme()

    return (
        <BlockContainerWithSidePaddings
            container justifyContent={"center"}
            sx={{
                bgcolor: 'primary.dark',
                position: "fixed",
                bottom: '0',
                zIndex: theme.zIndex.drawer - 1
            }}
        >
            <ThemeProvider theme={primaryDarkerTheme}>
                <Button variant={"contained"} onClick={onClick} disabled={disabled}>{label}</Button>
            </ThemeProvider>
        </BlockContainerWithSidePaddings>
    )
}

export default MainButtonBlock