import React, {FC, PropsWithChildren, ReactElement} from "react"
import {Fab, Grid, GridProps} from "@mui/material"
import {ThemeProvider} from "@mui/material/styles"
import AddIcon from "@mui/icons-material/Add"
import PeopleAltIcon from "@mui/icons-material/PeopleAlt"
import WorkIcon from "@mui/icons-material/Work"
import ExitToAppIcon from "@mui/icons-material/ExitToApp"
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import {primaryDarkerTheme, whiteTheme, mainTheme} from "@kashalot-web/ui"

interface IWhiteIconContainer {
    onClick?: () => void
    href?: string
}

const WhiteIconContainer: FC<PropsWithChildren<IWhiteIconContainer & GridProps>> = (props): ReactElement => {
    const {children, onClick, href, ...gridProps} = props

    return (
        <ThemeProvider theme={whiteTheme}>
            <Grid {...gridProps}>
                <Fab size="small" color={"primary"} onClick={onClick} href={href}>
                    <ThemeProvider theme={mainTheme}>
                        {children}
                        {/*<PeopleAltIcon color={"primary"}/>*/}
                    </ThemeProvider>
                </Fab>
            </Grid>
        </ThemeProvider>
    )
}

interface INavigationButtons {
    logout: () => void
    navigate: (path: string) => void
}

const NavigationButtons: FC<INavigationButtons> = ({logout, navigate}): ReactElement => {
    return (
        <Grid item container direction={"column"} wrap={"nowrap"} justifyContent={"space-between"}>
            <Grid item container direction={"column"} wrap={"nowrap"} alignItems={"center"}>
                <ThemeProvider theme={primaryDarkerTheme}>
                    <Grid item>
                        <Fab color={"primary"} onClick={() => navigate('/create_order')}> <AddIcon/> </Fab>
                    </Grid>
                </ThemeProvider>
                <WhiteIconContainer onClick={() => navigate('/create_delivery')} item sx={{pt: '1vh'}} >
                    <PeopleAltIcon color={"primary"}/>
                </WhiteIconContainer>
                {/*<WhiteIconContainer onClick={() => navigate('/crate_shipping')} item sx={{pt: '1vh'}} >*/}
                {/*    <LocalShippingIcon color={"primary"}/>*/}
                {/*</WhiteIconContainer>*/}
            </Grid>
            <Grid item container direction={"column"} wrap={"nowrap"} alignItems={"center"}>
                <WhiteIconContainer href={"https://office.kashalot.me"} item sx={{mt: '1vh', mb: '3vh'}}>
                    <WorkIcon color={"primary"}/>
                </WhiteIconContainer>
                <WhiteIconContainer item onClick={logout}>
                    <ExitToAppIcon color={"primary"}/>
                </WhiteIconContainer>
            </Grid>
        </Grid>
    )
}

export default NavigationButtons