import React, {FC, ReactElement} from "react"
import {Fab, Grid, Typography} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"

interface IOrderCardTaxiInfo {
    vendor: TaxiPark
    auto: Auto | null
    status: Status
    cancel?: () => void
}

const OrderCardTaxiInfo: FC<IOrderCardTaxiInfo> = ({vendor, auto, status, cancel}): ReactElement => {
    return (
        <Grid
            item container
            justifyContent={"space-between"} alignItems={"center"} wrap={"nowrap"}
            sx={{p: '3px 0'}}
        >
            <Grid item container wrap={"nowrap"} alignItems={"center"}>
                <img src={vendor.logo} height={22}/>
                <Typography sx={{ml: '20px'}}>{
                    auto ? auto.brand + ' ' + auto.model + ' · ' + auto.color : status.title
                }</Typography>
            </Grid>
            <Grid item>
                {auto && (
                    <Typography
                        variant="h5"
                        color="white"
                        sx={{
                            bgcolor: (theme) => theme.palette.primary.dark,
                            p: '0.1em',
                            borderRadius: '0.5rem', minWidth: '5.2em'
                        }}
                        noWrap={true}
                        align="center"
                    >
                        {
                            auto.number.slice(0, 1) + " " +
                            auto.number.slice(1, 4) + " " +
                            auto.number.slice(4, 6)
                        }
                    </Typography>
                )}
            </Grid>
            {cancel !== undefined && (
                <Grid item>
                    <Fab color={"primary"} onClick={cancel} size={"small"}>
                        <DeleteIcon />
                    </Fab>
                </Grid>
            )}
        </Grid>
    )
}

export default OrderCardTaxiInfo