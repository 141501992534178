import React, {FC, ReactElement} from "react"
import {Divider, Grid, IconButton, InputBase, Paper} from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"

interface ISearchInput {
    openFilterModals: any
    inputValue: string
    handleInputValueChange: (value: string) => void
}

const SearchInput: FC<ISearchInput> = ({openFilterModals, handleInputValueChange, inputValue}): ReactElement => {
    return (
        <Paper
            component={Grid}
            container
            // sx={{height: '100%'}}
        >
            <IconButton sx={{p: '10px'}} onClick={openFilterModals}>
                <MenuIcon/>
            </IconButton>
            <Divider orientation="vertical" />
            <InputBase
                sx={{ml: 1, flex: 1}}
                placeholder="Вводите запрос"
                value={inputValue}
                onChange={(event) => handleInputValueChange(event.target.value)}
            />
        </Paper>
    )
}

export default SearchInput