import React, {FC, ReactElement, useEffect, useState} from "react"
import CreateOrderMobileNew from "./new"

const CreateOrderMobile: FC = (): ReactElement => {

    return (
        <CreateOrderMobileNew />
    )
}

export default CreateOrderMobile