import React, {FC, ReactElement} from "react"
import {Button, Divider, Grid, GridProps, Typography} from "@mui/material"
import {ThemeProvider} from "@mui/material/styles"
import AccessTimeFilledIcon from '@mui/icons-material/AccessTime'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import {primaryDarkTheme} from "@kashalot-web/ui/dist"

interface IMainButton {
    disabled?: boolean
    onClick: () => void
    label: string
    onClickPayment?: () => void
    onClickDate?: () => void
    sx?: GridProps["sx"]
}

const MainButton: FC<IMainButton> = (props): ReactElement => {
    const {disabled, onClick, label, onClickDate, onClickPayment, sx} = props
    const sxProps = sx ? sx : {}

    return (
        <Grid
            container
            justifyContent="space-between"
            wrap="nowrap"
            sx={{...sxProps, overflow: 'hidden', p: '0.7rem', bgcolor: 'primary.light'}}
        >
            {onClickPayment && (
                <Grid
                    item container justifyContent="center" alignItems="center"
                    sx={{
                        mr: '0.3rem',
                        minHeight: '3.4rem', maxHeight: '3.4rem', minWidth: '3.4rem', maxWidth: '3.4rem',
                        borderRadius: '1000px', bgcolor: 'primary.dark',
                        pointerEvents: 'auto'
                        // zIndex: (theme) => theme.zIndex.drawer + 5
                    }}
                    onClick={onClickPayment}
                >
                    <AccountBalanceWalletIcon sx={{color: 'white'}}/>
                </Grid>
            )}
            {onClickDate && (
                <Grid
                    item container justifyContent="center" alignItems="center"
                    sx={{
                        mr: '0.3rem',
                        minHeight: '3.4rem', maxHeight: '3.4rem', minWidth: '3.4rem', maxWidth: '3.4rem',
                        borderRadius: '1000px', bgcolor: 'primary.dark',
                        pointerEvents: 'auto'
                        // zIndex: (theme) => theme.zIndex.drawer + 5
                    }}
                    onClick={onClickDate}
                >
                    <AccessTimeFilledIcon sx={{color: 'white'}}/>
                </Grid>
            )}
            <Grid container item sx={{height: '3.4rem'}}>
                <ThemeProvider theme={primaryDarkTheme}>
                    <Button
                        disabled={disabled}
                        color="primary"
                        variant="contained"
                        sx={{
                            width: '100%', height: '100%',
                            borderRadius: '100px',
                            pointerEvents: 'auto',
                            // zIndex: (theme) => theme.zIndex.drawer + 5,
                        }}
                        onClick={onClick}
                    >
                        <Typography color="common.white">
                            {label}
                        </Typography>
                    </Button>
                </ThemeProvider>
            </Grid>
        </Grid>
    )
}

export default MainButton