import React, {FC, ReactElement, useEffect, useState} from "react"
import {Grid, TextField} from "@mui/material"
import {useStyles} from "./Styles"
import AddressesSuggestions from "../AddressesSuggestions"
import {CurtainBody, CurtainHeader, CurtainPanel} from "../CurtainPanel"
import RoutePointInput from '../inputs/RoutPointInput'

interface ICommonDetailsPanel {
    comment: string
    setComment: (newComment: string) => void
    address: string
    setAddress: (newAddress: string) => void
    suggestionsRequest: SuggestionsRequest | null
    pushSuggestionRequest: (inputValue: string) => void
    open: () => void
    close: () => void
    isOpen: boolean
}
export const CommonDetailsPanel: FC<ICommonDetailsPanel> = (props): ReactElement => {
    const classes = useStyles()
    const {
        comment, setComment,
        address, setAddress,
        suggestionsRequest, pushSuggestionRequest,
        open, close, isOpen,
    }: ICommonDetailsPanel = props

    const [inputValueAddress, setInputValueAddress] = useState<string>(address)
    const handleAddressInputChange = (value: string) => {
        setInputValueAddress(value)
        pushSuggestionRequest(value)
    }
    const handleAddressSelected = (newAddress: string) => {
        setAddress(newAddress)
        pushSuggestionRequest(newAddress)
        setInputValueAddress(newAddress)
    }

    const [activeAddressInput, setActiveAddressInput] = useState<boolean>(true)

    useEffect(() => {
        if (isOpen) {
            setInputValueAddress(address)
            pushSuggestionRequest(address)
        }
    }, [isOpen])

    return (
        <CurtainPanel
            open={open} close={close} isOpen={isOpen}
            iconContainerWidth="3.4rem"
            iconContainerHeight="2.7rem"
        >
            <CurtainHeader>
                <Grid
                    sx={{bgcolor: 'primary.main'}}
                    item container
                    direction="column"
                    className={classes.header}
                >
                    <Grid
                        item
                        sx={{padding: '2px 20px'}}
                    >
                        <RoutePointInput
                            value={inputValueAddress}
                            onChange={value => handleAddressInputChange(value)}
                            onFocus={() => setActiveAddressInput(true)}
                            onBlur={() => setTimeout(() => setActiveAddressInput(false), 100)}
                            placeholder="Начните вводить адрес"
                            deleteField={() => handleAddressInputChange("")}
                        />
                    </Grid>
                    <Grid
                        item
                        sx={{padding: '2px 20px'}}
                    >
                        <TextField
                            autoComplete='off'
                            placeholder="Введите комментарий"
                            value={comment}
                            onChange={(event) => setComment(event.target.value)}
                            fullWidth
                            variant="standard"
                            InputProps={{disableUnderline: true}}
                        />
                    </Grid>
                </Grid>
            </CurtainHeader>
            <CurtainBody height='21rem'>
                <Grid
                    container item
                    direction="column"
                    wrap="nowrap"
                >
                    <AddressesSuggestions
                        activeAddressInput={activeAddressInput}
                        suggestionsRequest={suggestionsRequest}
                        setDestination={handleAddressSelected}
                    />
                </Grid>
            </CurtainBody>
        </CurtainPanel>
    )
}