import {FC, ReactElement} from "react"
import {useMap, useNewDelivery} from "@kashalot-web/react/dist"
import {CommonDetailsPanel} from "../../../../LocalUICore/mobile/NewDeliveryPanels/CommonDetailsPanel"

const KeyRequestAddress = "new-delivery-address"

interface ICommonDetailsPanel {
    isOpen: boolean,
    open: () => void
    close: () => void
}

const CommonDetailsPanelContainer: FC<ICommonDetailsPanel> = ({isOpen, open, close}): ReactElement => {
    const {delivery, updateDeliveryDetail} = useNewDelivery()
    const {suggestionsRequests, pushSuggestionRequest} = useMap()

    const suggestionsRequest = suggestionsRequests.find((suggestionsRequest: SuggestionsRequest) => suggestionsRequest.id === KeyRequestAddress) || null

    return (
        <CommonDetailsPanel
            comment={delivery.comment}
            setComment={(newComment: string) => updateDeliveryDetail("comment", newComment)}
            address={delivery.startingAddress}
            setAddress={(newAddress: string) => updateDeliveryDetail("startAddress", newAddress)}
            suggestionsRequest={suggestionsRequest}
            pushSuggestionRequest={(inputValue: string) => pushSuggestionRequest(KeyRequestAddress, inputValue)}
            open={open}
            close={close}
            isOpen={isOpen}
        />
    )
}

export default CommonDetailsPanelContainer