import React, {FC, Fragment, ReactElement} from "react"
import {Divider, Grid, Typography} from "@mui/material"
import {OfferCard} from "./OfferCards"
import {CurtainBody, CurtainHeader, CurtainPanel} from "./CurtainPanel"

interface IOffersListPanel {
    isOpen: boolean
    open: () => void
    close: () => void
    order: IOrder
    offers: Array<IOffer>
    selectOffer: (offerID: string) => void
    setOfferDelay: (offerID: string, delay: number) => void
    selectedOffers: Array<IOffer>
    title: string
}

const OffersListPanel: FC<IOffersListPanel> = (props): ReactElement => {
    const {
        close,
        order,
        offers, selectOffer, selectedOffers, setOfferDelay,
        title
    } = props

    return (
        <CurtainPanel
            {...props}
            iconContainerWidth="3.4rem"
            iconContainerHeight="2.7rem"
        >
            <CurtainBody>
                <Grid
                    container item
                    direction="column"
                    wrap="nowrap"
                >
                    <Grid item container sx={{p: '8px 8px 0 8px'}}>
                        <Typography >{title}</Typography>
                    </Grid>
                    {
                        offers.length === 0 ? (
                            <> нет предложений </>
                        ) : (
                            offers.map((offer: IOffer, ind: number) => {
                                return (
                                    <Fragment key={`offer-card-${ind}`}>
                                        <OfferCard
                                            offer={offer}
                                            onClick={() => {
                                                selectOffer(offer.id)
                                                if (!order.offersManager.delayed) {
                                                    close()
                                                }
                                            }}
                                            delayed={!!order.offersManager.delayed}
                                            isSelected={selectedOffers ? !!selectedOffers.find(selectedOffer => selectedOffer.id === offer.id) : false}
                                            setDelay={newDelay => setOfferDelay(offer.id, newDelay)}
                                        />
                                        <Divider />
                                    </Fragment>
                                )
                            })
                        )
                    }
                </Grid>
                <Grid item container sx={{height: '16vw'}} />
            </CurtainBody>
        </CurtainPanel>
    )
}

export default OffersListPanel