import {FC, ReactElement, Children, PropsWithChildren} from "react"
import Curtain from "./Curtain"
import {DrawerProps, Grid, SwipeableDrawerProps} from "@mui/material"

const CurtainHeader: FC<PropsWithChildren<unknown>> = ({children}): ReactElement => {
    return (
        <>
            {children}
        </>
    )
}

interface ICurtainBody {
    height?: string
}

const CurtainBody: FC<PropsWithChildren<ICurtainBody>> = ({children, height}): ReactElement => {
    const arrayChildren = Children.toArray(children)
    const Tabs: ReactElement[] = []
    arrayChildren.forEach((child) => {
        if (child && (child as JSX.Element).type && (child as JSX.Element).type === CurtainTab) {
            Tabs.push((child as JSX.Element))
        }
    })
    const activeTabIndex = Tabs.findIndex(tab => tab.props.isActive)
    if (Tabs.length > 0) {
        return (
            <Grid
                item container
                sx={{
                    width: '100%',
                    height: height,
                    overflow: 'hidden',
                    bgcolor: 'white'
                }}
            >
                <Grid
                    item container wrap="nowrap"
                    sx={{
                        height: '100%',
                        width: (Tabs.length * 100).toString() + '%',
                        transform: `translateX(${(-1) * activeTabIndex * (100 / Tabs.length)}%)`,
                        transition: (theme) => theme.transitions.create(
                            ['transform'],
                            {duration: theme.transitions.duration.complex}
                        )
                    }}
                >
                    {Tabs.map((tab, index) => (
                        <Grid
                            key={`tab-${index}`}
                            sx={{width: '100vw'}}
                            item container
                        >
                            {tab}
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        )
    }
    return (
        <Grid
            item container
            sx={{
                height: height,
                bgcolor: 'white'
            }}
        >
            {children}
        </Grid>
    )
}

interface ICurtainTab {
    icon: ReactElement,
    onClickIcon: () => void,
    isActive: boolean
}

const CurtainTab: FC<PropsWithChildren<ICurtainTab>> = ({children, icon}): ReactElement => {
    return (
        <>{children}</>
    )
}

export interface ICurtainPanel {
    open: () => void
    close: () => void
    isOpen: boolean
    iconContainerWidth?: string
    iconContainerHeight?: string
    sx?: SwipeableDrawerProps["sx"] | DrawerProps["sx"]
}

const CurtainPanel: FC<PropsWithChildren<ICurtainPanel>> = (props): ReactElement => {
    const {open, close, isOpen, children, iconContainerWidth, iconContainerHeight, sx} = props

    const arrayChildren = Children.toArray(children)

    let Header: ReactElement = <></>
    let Body: ReactElement = <></>
    try {
        arrayChildren.forEach((child) => {
            if (child && (child as JSX.Element).type) {
                if ((child as JSX.Element).type === CurtainHeader) {
                    Header = (child as JSX.Element)
                } else if ((child as JSX.Element).type === CurtainBody) {
                    Body = (child as JSX.Element)
                }
            }
        })
    } catch (e) {

    }

    const Tabs: ReactElement[] = []
    try {
        Body.props.children.forEach((child: any) => {
            if (child && (child as JSX.Element).type && (child as JSX.Element).type === CurtainTab) {
                Tabs.push((child as JSX.Element))
            }
        })
    } catch (e) {

    }

    if (Tabs && Tabs.length > 0) {
        const activeTabIndex = Tabs.findIndex(tab => (tab as JSX.Element).props.isActive)
        const TabIcons = Tabs.map(Tab => Tab.props.icon)
        const TabIconsClick = Tabs.map(Tab => Tab.props.onClickIcon)
        return (
            <Curtain
                open={open}
                close={close}
                isOpen={isOpen}
                sx={sx}
            >
                {Header}
                <Grid
                    item container
                    wrap="nowrap"
                    sx={{bgcolor: 'white', borderBottom: '1px solid #E3E3E3', position: 'relative'}}
                >
                    {TabIcons.map((TabIcon: any, index: number) => (
                        <Grid
                            key={`tab-icon-${index}`}
                            item container
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                width: iconContainerWidth || "",
                                height: iconContainerHeight || "",
                            }}
                            onClick={TabIconsClick[index]}
                        >
                            {TabIcon}
                        </Grid>
                    ))}
                    <Grid
                        sx={{
                            width: iconContainerWidth || "",
                            height: '4px',
                            bgcolor: 'primary.main',
                            position: 'absolute',
                            bottom: 0,
                            transform: `translateX(${(activeTabIndex * 100).toString()}%)`,
                            transition: (theme) => theme.transitions.create(
                                ['transform'],
                                {duration: theme.transitions.duration.complex}
                            )
                        }}
                        item
                    />
                </Grid>
                {Body}
            </Curtain>
        )
    }

    return (
        <Curtain
            open={open}
            close={close}
            isOpen={isOpen}
            sx={sx}
        >
            <Grid
                container item
                direction="column"
                wrap="nowrap"
                justifyContent="space-between"
            >
                {Header}
                {Body}
            </Grid>
        </Curtain>
    )
}

export {CurtainPanel, CurtainHeader, CurtainBody, CurtainTab}