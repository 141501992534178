import React, {FC, ReactElement} from "react"
import {Grid, Typography, Button} from "@mui/material"

interface ICounterButton {
    add: () => void
    sub: () => void
    value: number
}

const CounterButton: FC<ICounterButton> = ({add, sub, value}): ReactElement => {
    return (
        <Grid
            container
            wrap={"nowrap"} justifyContent={"flex-end"}
            sx={{
                height: '100%',
                boxShadow: '0px 1px 4px 0px rgba(34, 60, 80, 0.8)',
                borderRadius: '5px'
            }}
        >
            <Button
                variant={"contained"} onClick={add}
                sx={{
                    borderTopRightRadius: '0px', borderBottomRightRadius: '0px',
                    boxShadow: 'none', '&:focus': {boxShadow: 'none'},
                    maxWidth: '40px',
                    minWidth: '40px',
                }}
            >+</Button>
            <Typography
                sx={{
                    borderTop: '1px solid', borderBottom: '1px solid', borderColor: 'primary.main',
                    pt: '2px', pl: '4px', pr: '4px',
                    bgcolor: 'white',
                    width: '18px'
                }}
                align={"center"}
            >
                {value}
            </Typography>
            <Button
                variant={"contained"} onClick={sub}
                sx={{
                    borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px',
                    boxShadow: 'none', '&:focus': {boxShadow: 'none'},
                    maxWidth: '40px',
                    minWidth: '40px',
                }}
            >-</Button>
        </Grid>
    )
}

interface IDeliveryMemberListItem {
    employee: Employee
    deliveryMember: DeliveryMember | undefined
    isSelect: boolean
    onClick: () => void
    onAdd: () => void
    onSub: () => void
}

const DeliveryMemberListItem: FC<IDeliveryMemberListItem> = (props): ReactElement => {
    const {employee, isSelect, onClick, deliveryMember, onAdd, onSub} = props

    return (
        <Grid
            item container
            justifyContent={"space-between"} alignItems={"center"}
            wrap={"nowrap"}
            sx={isSelect ? {bgcolor: 'primary.light'} : {}}
        >
            <Grid item container onClick={onClick}>
                <Typography
                    variant="body2"
                    sx={{padding: '10px'}}
                    // color={isSelect ? "common.white" : ""}
                >
                    {employee.fio || employee.email}
                </Typography>
            </Grid>
            {isSelect && (
                <Grid item sx={{height: '75%', pr: '8px'}}>
                    <CounterButton
                        value={deliveryMember?.amount || 0}
                        add={onAdd}
                        sub={onSub}
                    />
                </Grid>
            )}
        </Grid>
    )
}

export default DeliveryMemberListItem