import {
    Grid,
    SwipeableDrawer,
    SwipeableDrawerProps,
    Theme
} from "@mui/material"
import React, {FC, PropsWithChildren, ReactElement} from "react"
import {createStyles, makeStyles} from "@mui/styles"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100vw',
            height: "100vh",
            overflow: 'hidden'
        },
        pullerContainer: {
            backgroundColor: theme.palette.primary.main,
            padding: '7px',
            borderRadius: '100px 100px 0 0'
        },
        puller: {
            width: '50px',
            height: '10px',
            backgroundColor: theme.palette.primary.light,
            borderRadius: '100px'
        }
    })
)


interface ICurtain {
    open: () => void
    close: () => void
    isOpen: boolean
    sx?: SwipeableDrawerProps["sx"]
}

const Curtain: FC<PropsWithChildren<ICurtain>> = (props): ReactElement => {
    const classes = useStyles()
    const {children, open, close, isOpen, sx} = props

    return (
        <SwipeableDrawer
            anchor="bottom"
            open={isOpen}
            onClose={close}
            onOpen={open}
            PaperProps={{style: {backgroundColor: 'transparent'}}}
            disableSwipeToOpen={true}
            sx={sx}
        >
            <Grid
                container
                direction="column"
                wrap="nowrap"
                sx={{overflow: 'hidden', maxHeight: '100vh'}}
            >
                <Grid
                    item container justifyContent="center"
                    className={classes.pullerContainer}
                >
                    <Grid item className={classes.puller}/>
                </Grid>
                {children}
            </Grid>
        </SwipeableDrawer>
    )
}

export default Curtain