import React, {FC, ReactElement} from "react"
import BackdropContainer from "../../../LocalUICore/common/BackdropContainer"
import {useNewDelivery} from "@kashalot-web/react/dist"
import {Backdrop, CircularProgress} from "@mui/material"


const HandlerDeliveryBackdrops: FC = (): ReactElement => {
    const {delivery} = useNewDelivery()

    return (
        <>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={delivery.status === "loading"}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <BackdropContainer
                open={delivery.status === "calcing"}
            />
        </>
    )
}

export default HandlerDeliveryBackdrops