import {FC, ReactElement} from "react"
import CompanyEmployeeBlock from "../../../../LocalUICore/desktop/NewOrderBlocks/CompanyEmployeeBlock"
import {useDispatcher, useDispatcherRequest, useNewShipping} from "@kashalot-web/react/dist"
import {GET_CLIENTS_REQ_ID} from "../../../../requestIDs"

const CompanyEmployeeBlockContainer: FC = (): ReactElement => {
    const {clientsManager} = useDispatcher()
    const [status] = useDispatcherRequest(GET_CLIENTS_REQ_ID)
    const {shipping, editOrderDetail} = useNewShipping()

    return (
        <CompanyEmployeeBlock
            loading={status === "pending"}
            organization={shipping.orderDetails.organization}
            organizations={status === "pending" ? [] : clientsManager?.getOrganizations() || []}
            onChangeOrganization={org => editOrderDetail("organization", org)}
            employee={shipping.orderDetails.employee}
            employees={shipping.orderDetails.organization && clientsManager?.getEmployeesByOrganizationID(shipping.orderDetails.organization.id, true) || []}
            onChangeEmployee={employee => editOrderDetail("employee", employee)}
        />
    )
}

export default CompanyEmployeeBlockContainer