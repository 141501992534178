import {FC, PropsWithChildren, ReactElement} from "react"
import {Grid} from "@mui/material"

const FullScreenContainer: FC<PropsWithChildren<unknown>> = ({children}): ReactElement => {
    return (
        <Grid
            item container
            sx={{
                width: '100vw',
                height: 'calc(var(--vh, 1vh) * 100)',
                overflow: 'hidden',
                position: 'relative',
            }}
        >
            {children}
        </Grid>
    )
}

export default FullScreenContainer