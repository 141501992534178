import React, {FC, ReactElement, useState} from "react"
import {Backdrop, Button, Divider, Fade, Grid, Modal, Paper, Typography} from "@mui/material"

interface IModalFreeMembers {
    freeMembers: Array<DeliveryMember>
    submit: (selectedMembers: Array<DeliveryMember>) => void
    isOpen: boolean
    close: () => void
}

const ModalFreeMembers: FC<IModalFreeMembers> = ({freeMembers, submit, isOpen, close}): ReactElement => {
    const [selectedMembers, setSelectedMembers] = useState<Array<DeliveryMember>>([])
    const toggleMember = (targetMember: DeliveryMember) => {
        const candidate = selectedMembers.find(member => member.id === targetMember.id)
        if (candidate) {
            setSelectedMembers(selectedMembers.filter(member => member.id !== targetMember.id))
        } else {
            setSelectedMembers([...selectedMembers, targetMember])
        }
    }
    return (
        <Modal
            open={isOpen}
            onClose={() => {
                setSelectedMembers([])
                close()
            }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{timeout: 200}}
        >
            <Fade in={isOpen}>
                <Paper
                    component={Grid}
                    container
                    direction="column"
                    wrap="nowrap"
                    sx={{
                        width: '88vw',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                >
                    {freeMembers.map((freeMember, index) => {
                        const candidate = !!selectedMembers.find(member => member.id === freeMember.id)
                        return (
                            <React.Fragment key={`free-member-${index}`}>
                                <Grid
                                    item container
                                    direction="column"
                                    wrap="nowrap"
                                    sx={candidate ? {p: '2vw', bgcolor: 'primary.light'} : {p: '2vw'}}
                                    onClick={() => toggleMember(freeMember)}
                                >
                                    <Grid item>
                                        <Typography>
                                            {freeMember.fio || freeMember.email} x{freeMember.amount}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography>
                                            {freeMember.address.split(',').filter((_, i) => i < 2).join(',')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider />
                            </React.Fragment>
                        )
                    })}
                    <Grid
                        item container
                        direction="column"
                        alignItems="center"
                        wrap="nowrap"
                        sx={{p: '2vw'}}
                    >
                        <Grid item>
                            <Button
                                variant="contained"
                                disabled={selectedMembers.length === 0}
                                onClick={() => {
                                    submit(selectedMembers)
                                    setSelectedMembers([])
                                    close()
                                }}
                            >
                                Применить
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Fade>
        </Modal>
    )
}

export default ModalFreeMembers