import React, {FC, ReactElement, useEffect} from "react"
import CreateShippingDesktop from "./CreateShippingDesktop"
import {useDispatcher, useNewShipping, useOrganization, useProfile} from "@kashalot-web/react/dist"
import {GET_CLIENTS_REQ_ID, GET_EMPLOYEES_REQ_ID} from "../../requestIDs"
import {Theme, useMediaQuery} from "@mui/material"
import EmployeeTypes from "@kashalot-web/core/dist/TaxiInfo/EmployeeTypes"

const CreateShippingPage: FC = (): ReactElement => {
    const {userInfo} = useProfile()
    const {startListeningPrices, stopListeningPrices, editOrderDetail, shipping} = useNewShipping()
    const {getEmployeesList, employeesManager} = useOrganization()
    const {getClients} = useDispatcher()

    useEffect(() => {
        if (userInfo) {
            if ([EmployeeTypes.Dispatcher.id, EmployeeTypes.DispatcherWorker.id].includes(userInfo.employeeType.id)) {
                getClients(GET_CLIENTS_REQ_ID)
            } else if ([...EmployeeTypes.getBranchEmployees(), ...EmployeeTypes.getCompanyEmployees()]
                .map(e => e.id).includes(userInfo.employeeType.id)) {
                getEmployeesList(GET_EMPLOYEES_REQ_ID)
            }
        }
        startListeningPrices()
        return () => stopListeningPrices()
    }, [])

    useEffect(() => {
        let candidate: Employee | null = null
        if (userInfo
            && [...EmployeeTypes.getBranchEmployees(), ...EmployeeTypes.getCompanyEmployees()].map(e => e.id).includes(userInfo.employeeType.id)
            && employeesManager && (!shipping.orderDetails.employee)) {
            candidate = employeesManager.getActiveEmployees().find(employee => employee.id === userInfo.userID) || null
            if (candidate) {
                editOrderDetail("employee", candidate)
            }
        }
    }, [employeesManager])

    // const xl = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'))
    // const lg = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
    // const md = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))
    const sm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
    // const xs = useMediaQuery((theme: Theme) => theme.breakpoints.up('xs'))

    // if (sm) {
    //     return (
    //
    //     )
    // }

    return (
        <CreateShippingDesktop />
    )
}

export default CreateShippingPage