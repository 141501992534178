import React, {FC, ReactElement} from "react"
import FastAndCheap from "../../../../LocalUICore/mobile/FastAndCheap"
import {useNewOrder} from "@kashalot-web/react"


const FastAndCheapContainer: FC = (): ReactElement => {
    const {order, selectOffer, setOfferDelayedFeed} = useNewOrder()

    return (
        <FastAndCheap
            order={order}
            fastOffers={order.offersManager.getFastOffers(5)}
            cheapOffers={order.offersManager.getCheapOffers(5)}
            selectedOffers={order.offersManager.selectedOffers}
            selectedOfferType={order.offersManager.selectedOfferType}
            selectOffer={selectOffer}
            loading={order.status === "waiting-offers"}
            setOfferDelay={setOfferDelayedFeed}
        />
    )
}

export default FastAndCheapContainer