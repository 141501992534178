import React, {FC, ReactElement} from "react"
import {
    Button,
    ButtonGroup,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    TextField
} from "@mui/material"

export interface IDelayedOptionsInput {
    delayedOptions: DelayedFeedOptions | null
    onChangeDelayedOptions: (newOptions: DelayedFeedOptions | null) => void
}

const DelayedOptionsInput: FC<IDelayedOptionsInput> = (props): ReactElement => {
    const {delayedOptions, onChangeDelayedOptions} = props

    return (
        <Grid container>
            <Grid item sx={{mr: '24px'}}>
                <FormControl>
                    <FormLabel id="urgency-radio-selector">Автоназначение</FormLabel>
                    <RadioGroup
                        aria-labelledby="urgency-radio-selector"
                        value={!!delayedOptions}
                        onChange={e => {
                            if (e.target.value === "true") {
                                onChangeDelayedOptions({
                                    maxDelayTime: 15,
                                    ratePriceDiff: 15,
                                    minPriceDiff: 150
                                })
                            } else {
                                onChangeDelayedOptions(null)
                            }
                        }}
                    >
                        <FormControlLabel value={true} control={<Radio />} label="Вкл" />
                        <FormControlLabel value={false} control={<Radio />} label="Выкл" />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item>
                <Grid container spacing={2}>
                    <Grid item>
                        <TextField
                            label={"Отлож. подача, мин"}
                            type={"number"}
                            value={delayedOptions?.maxDelayTime || 0}
                            sx={{maxWidth: 160}}
                            onChange={e => {
                                if (delayedOptions) {
                                    const value = e.target.value
                                    try {
                                        const numb = Number.parseInt(value)
                                        onChangeDelayedOptions({...delayedOptions, maxDelayTime: numb})
                                    } catch (e) {}
                                }
                            }}
                            disabled={!delayedOptions}
                            InputProps={{readOnly: !delayedOptions}}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label={"Разница, %"}
                            type={"number"}
                            value={delayedOptions?.ratePriceDiff || 0}
                            sx={{maxWidth: 160}}
                            onChange={e => {
                                if (delayedOptions) {
                                    const value = e.target.value
                                    try {
                                        const numb = Number.parseInt(value)
                                        onChangeDelayedOptions({...delayedOptions, ratePriceDiff: numb})
                                    } catch (e) {}
                                }
                            }}
                            disabled={!delayedOptions}
                            InputProps={{readOnly: !delayedOptions}}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label={"Минимальная разница, руб"}
                            type={"number"}
                            value={delayedOptions?.minPriceDiff || 0}
                            sx={{maxWidth: 160}}
                            onChange={e => {
                                if (delayedOptions) {
                                    const value = e.target.value
                                    try {
                                        const numb = Number.parseInt(value)
                                        onChangeDelayedOptions({...delayedOptions, minPriceDiff: numb})
                                    } catch (e) {}
                                }
                            }}
                            disabled={!delayedOptions}
                            InputProps={{readOnly: !delayedOptions}}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default DelayedOptionsInput