import React, {FC, ReactElement} from "react"
import useNewOrder from "@kashalot-web/react/dist/useNewOrder"
import TaxiClasses from "@kashalot-web/core/dist/TaxiInfo/TaxiClasses"
import CarClassesPanel from "../../../../LocalUICore/mobile/NewOrderPanels/CarClassesPanel"

interface ICarClassesPanelContainer {
    isOpen: boolean,
    open: () => void
    close: () => void
}

const CarClassesPanelContainer: FC<ICarClassesPanelContainer> = ({isOpen, open, close}): ReactElement => {
    const {order, editOrderDetail} = useNewOrder()

    return (
        <CarClassesPanel
            isOpen={isOpen}
            open={open}
            close={close}
            taxiClasses={TaxiClasses.getAllClasses()}
            selectedClass={order.orderDetails.carClass}
            selectCarClass={(taxiClass: TaxiClass) => editOrderDetail("carClass", taxiClass)}
        />
    )
}

export default CarClassesPanelContainer