import React, {FC, ReactElement, useState} from "react"
import useNewOrder from "@kashalot-web/react/dist/useNewOrder"
import DeadlinePanel from "../../../../LocalUICore/mobile/NewDeliveryPanels/DeadlinePanel"
import {LocalizationProvider, MobileDateTimePicker} from "@mui/lab"
import AdapterMoment from "@mui/lab/AdapterMoment"

interface IDatePanelContainer {
    isOpen: boolean,
    open: () => void
    close: () => void
}

const DatePanelContainer: FC<IDatePanelContainer> = ({isOpen, open, close}): ReactElement => {
    const {order, editOrderDetail} = useNewOrder()

    const [isOpenPicker, setIsOpenPicker] = useState<boolean>(false)
    const openPicker = () => setIsOpenPicker(true)
    const closePicker = () => setIsOpenPicker(false)

    return (
        <>
            <DeadlinePanel
                open={open} close={close} isOpen={isOpen}
                isUrgent={order.orderDetails.isUrgent}
                deadline={order.orderDetails.deadline}
                selectIsUrgent={() => editOrderDetail("isUrgent", true)}
                selectDeadline={() => {
                    editOrderDetail("isUrgent", false)
                    openPicker()
                }}
            />
            <LocalizationProvider dateAdapter={AdapterMoment}>
                {/*TODO: изменение времени по закрытию окна*/}
                <MobileDateTimePicker
                    renderInput={() => <></>}
                    ampm={false}
                    disablePast
                    value={order.orderDetails.deadline}
                    onChange={(newDate: any) => editOrderDetail("deadline", newDate)}
                    open={isOpenPicker}
                    onOpen={openPicker}
                    onClose={closePicker}
                />
            </LocalizationProvider>
        </>
    )
}

export default DatePanelContainer