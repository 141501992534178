import React, {FC, ReactElement} from "react"
import {CurtainBody, CurtainPanel} from "../CurtainPanel"
import {Grid, Typography} from "@mui/material"

interface ICarClassesPanel {
    isOpen: boolean
    open: () => void
    close: () => void
    taxiClasses: Array<TaxiClass>
    selectedClass: TaxiClass
    selectCarClass: (carClass: TaxiClass) => void
}

const CarClassesPanel: FC<ICarClassesPanel> = (props): ReactElement => {
    const {isOpen, open, close, taxiClasses, selectedClass, selectCarClass} = props

    return (
        <CurtainPanel
            open={open} close={close} isOpen={isOpen}
            iconContainerWidth="3.4rem"
            iconContainerHeight="2.7rem"
        >
            <CurtainBody>
                <Grid
                    container item
                    direction="column"
                    wrap="nowrap"
                >
                    {taxiClasses.map((taxiClass, index) => {
                        const isSelected = taxiClass.id === selectedClass.id
                        const typographyProps = isSelected ? {color: "common.white"} : {}
                        return (
                            <Grid
                                item key={`employee-${index}`}
                                onClick={() => {
                                    selectCarClass(taxiClass)
                                    close()
                                }}
                                sx={isSelected ? {bgcolor: 'primary.main'} : {}}
                            >
                                <Typography
                                    variant="body2"
                                    sx={{padding: '10px'}}
                                    {...typographyProps}
                                >
                                    {taxiClass.title}
                                </Typography>
                            </Grid>
                        )
                    })}
                </Grid>
            </CurtainBody>
        </CurtainPanel>
    )
}

export default CarClassesPanel