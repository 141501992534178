import {FC, ReactElement} from "react"
import OptionsDeliveryBlock from "../../../../LocalUICore/desktop/NewDeliveryBlocks/OptionsDeliveryBlock"
import TaxiClasses from '@kashalot-web/core/dist/TaxiInfo/TaxiClasses'
import {useNewDelivery} from "@kashalot-web/react/dist"

const OptionsDeliveryBlockContainer: FC = (): ReactElement => {
    const {delivery, updateDeliveryDetail, setDelayedFeed} = useNewDelivery()

    return (
        <OptionsDeliveryBlock
            allTaxiClasses={TaxiClasses.getAllClasses()}
            selectedTaxiClass={delivery.carClass}
            onTaxiClassChange={carClass => updateDeliveryDetail("carClass", carClass)}
            isUrgent={delivery.isUrgent}
            onUrgencyChange={newValue => updateDeliveryDetail("isUrgent", newValue)}
            deadline={delivery.deadline}
            onDeadlineChange={newDeadline => updateDeliveryDetail("deadline", newDeadline)}
            delayedOptions={delivery.delayed}
            onChangeDelayedOptions={setDelayedFeed}
        />
    )
}

export default OptionsDeliveryBlockContainer