import React, {FC, ReactElement, useState} from "react"
import {Grid, TextField, Typography} from "@mui/material"
import BlockContainer from "../BlockContainer"
import {AddressInput} from "@kashalot-web/ui/dist"
import PhoneInput from "../PhoneInput"
import {makeID} from "@kashalot-web/core/dist/Utils/config"

interface IMemberInformation {
    member: ShippingMember
    onMemberChange: (newMember: ShippingMember) => void
    suggestionsRequests: SuggestionsRequest[]
    pushSuggestionRequest: (id: string, inputValue: string) => void
}

const MemberInformation: FC<IMemberInformation> = (props): ReactElement => {
    const {
        member, onMemberChange,
        suggestionsRequests, pushSuggestionRequest
    } = props

    const [addressID] = useState<string>(makeID(8))

    return (
        <>
            <BlockContainer item container direction={"column"} wrap={"nowrap"}>
                <Typography variant={"h5"}>Откуда</Typography>
            </BlockContainer>
            <BlockContainer item container spacing={2}>
                <Grid item>
                    <AddressInput
                        id={addressID}
                        placeholder={"Введите адрес"}
                        defaultValue={member.point.address}
                        onChange={newAddress => onMemberChange({...member, point: {...member.point, address: newAddress}})}
                        suggestionsRequests={suggestionsRequests}
                        pushSuggestionRequest={pushSuggestionRequest}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        sx={{width: 160}}
                        autoComplete={'off'}
                        label={"Подъезд"}
                        value={member.point.entrance}
                        onChange={e => onMemberChange({...member, point: {...member.point, entrance: e.target.value}})}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        sx={{width: 160}}
                        autoComplete={'off'}
                        label={"Квартира/офис"}
                        value={member.point.apartmentOffice}
                        onChange={e => onMemberChange({...member, point: {...member.point, apartmentOffice: e.target.value}})}
                    />
                </Grid>
            </BlockContainer>
            <BlockContainer item container spacing={2}>
                <Grid item>
                    <TextField
                        autoComplete={'off'}
                        label={"Имя"}
                        value={member.name}
                        onChange={e => onMemberChange({...member, name: e.target.value})}
                    />
                </Grid>
                <Grid item>
                    <PhoneInput
                        label={"Телефон"}
                        autoComplete={'off'}
                        value={member.phone}
                        onChange={e => onMemberChange({...member, phone: e.target.value})}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        sx={{width: 320}}
                        autoComplete={'off'}
                        label={"Комментарий"}
                        value={member.comment}
                        onChange={e => onMemberChange({...member, comment: e.target.value})}
                    />
                </Grid>
            </BlockContainer>
        </>
    )
}

export default MemberInformation