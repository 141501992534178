import React, {FC, ReactElement, useEffect} from "react"
import {Grid} from "@mui/material"
import {useNavigate} from "react-router-dom"
import TitleBlock from "../../../LocalUICore/desktop/NewOrderBlocks/TitleBlock"
import FilingAddressBlockContainer from "./BlockContainers/FilingAddressBlockContainer"
import InputMembersBlockContainer from "./BlockContainers/InputMembersBlockContainer"
import {BlockContainerWithSidePaddings} from "../../../LocalUICore/desktop/BlockContainer"
import MainButtonBlockContainer from "./BlockContainers/MainButtonBlockContainer"
import OrdersBlockContainer from "./BlockContainers/OrdersBlockContainer"
import OptionsDeliveryBlockContainer from "./BlockContainers/OptionsDeliveryBlockContainer"
import HandlerDeliveryBackdrops from "./HandlerDeliveryBackdrops"
import OrganizationSelectorBlockContainer from "./BlockContainers/OrganizationSelectorBlockContainer"
import {useNewDelivery, useProfile} from "@kashalot-web/react/dist"
import EmployeeTypes from "@kashalot-web/core/dist/TaxiInfo/EmployeeTypes"
import {useSnackbar} from "notistack"

const CreateDeliveryDesktop: FC = (): ReactElement => {
    const navigate = useNavigate()
    const {userInfo} = useProfile()
    const {delivery, resetDelivery} = useNewDelivery()
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        const redirect = !!delivery.orders && delivery.orders.length > 0
            && delivery.orders.reduce((acc, order) => order.status === "success" && acc, true)
        if (redirect) {
            enqueueSnackbar('Заказы успешно созданы', { variant: 'success' })
            resetDelivery()
            navigate('/')
        }
    }, [delivery])

    return (
        <Grid container direction={"column"} wrap={"nowrap"}>
            <HandlerDeliveryBackdrops/>
            <BlockContainerWithSidePaddings
                item container direction={"column"} wrap={"nowrap"}
                sx={{bgcolor: 'primary.dark'}}
            >
                <TitleBlock title={"Создание развозки"} close={() => navigate('/')}/>
                {userInfo?.employeeType
                && [EmployeeTypes.DispatcherWorker.id, EmployeeTypes.Dispatcher.id].includes(userInfo.employeeType.id)
                && (
                    <OrganizationSelectorBlockContainer/>
                )}
                <FilingAddressBlockContainer/>
            </BlockContainerWithSidePaddings>
            <BlockContainerWithSidePaddings
                item container
                sx={{bgcolor: 'primary.light'}}
            >
                <OptionsDeliveryBlockContainer/>
            </BlockContainerWithSidePaddings>
            <BlockContainerWithSidePaddings
                item container
                direction={"column"} wrap={"nowrap"}
            >
                <InputMembersBlockContainer/>
            </BlockContainerWithSidePaddings>
            <OrdersBlockContainer/>
            <MainButtonBlockContainer/>
            <Grid container sx={{height: '50px'}}/>
        </Grid>
    )
}

export default CreateDeliveryDesktop