import React, {FC, ReactElement, forwardRef, RefCallback} from "react"
import {IMaskInput} from "react-imask"
import {
    FormControl,
    Input,
    InputBaseComponentProps,
    InputLabel,
    OutlinedInput,
    OutlinedInputProps,
    TextField
} from "@mui/material";

interface ICustomOnChangeProp {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const TextMaskCustom = React.forwardRef<
    HTMLInputElement,
    Omit<InputBaseComponentProps, "onChange"> & ICustomOnChangeProp
    >((props, ref) => {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="+7 (000) 000-0000"
            lazy={false}
            inputRef={ref as RefCallback<HTMLTextAreaElement | HTMLInputElement>}
            onAccept={(value) =>
                onChange({ target: { name: props.name, value: value as string } })
            }
            overwrite
        />
    );
})



const PhoneInput: FC<OutlinedInputProps> = (props): ReactElement => {
    return (
        <FormControl sx={{width: 160}}>
            <InputLabel>{props.label}</InputLabel>
            <OutlinedInput
                {...props}
                inputComponent={TextMaskCustom as any}
            />
        </FormControl>
    )
}

export default PhoneInput