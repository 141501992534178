import React, {FC, ReactElement, useState} from "react"
import {Button, Grid, Typography} from "@mui/material"
import {useStyles} from "./Styles"
import Curtain from "../Curtain"
import Dot from "../../common/Dot"
import ModalFreeMembers from "./ModalFreeMembers"

interface IShortOrderCard {
    order: IDeliveryOrder
    openOrderPanel: () => void
    status: OrderStatus
}

const ShortOrderCard: FC<IShortOrderCard> = ({order, openOrderPanel, status}): ReactElement => {
    let statusColor: string = ""
    if (status === "error") {
        statusColor = "error.light"
    } else if (status === "success") {
        statusColor = "success.light"
    }
    return (
        <Grid
            container item
            direction="column"
            wrap="nowrap"
            onClick={openOrderPanel}
            sx={{
                p: '6px',
                borderBottom: '1px solid #E3E3E3',
                bgcolor: statusColor
            }}
        >
            <Grid item container justifyContent={"center"}>
                <Typography variant="body1">
                    {order.routeManager.routeInfo.travelLength} км. / {order.routeManager.routeInfo.travelTime} мин.
                </Typography>
            </Grid>
            <Grid item container justifyContent={"center"}>
                <Typography variant="body1" align={"center"}>
                    {order.orderDetails.customerName}
                </Typography>
            </Grid>
            <Grid item container sx={{p: '3px'}}>
                {order.routeManager.route.map((routePoint: RoutePoint, routePointIndex: number) => {
                    return (
                        <Grid
                            key={`delivery-order-route-point-${routePointIndex}`}
                            item container
                            alignItems="center"
                        >
                            <Grid item sx={{mr: '7px'}}><Dot size="14px"/></Grid>
                            <Grid item>
                                <Typography variant={"body2"}>
                                    {routePoint.address.split(',').filter((_, i) => i < 2).join(',')}
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
            {order.offersManager.selectedOffers.length !== 0 ? (
                order.offersManager.selectedOffers.map((selectedOffer, index) => {
                    return (
                        <Grid item key={`offer-${index}`}>
                            <Typography>
                                {
                                    selectedOffer.vendor.name + ' · ' +
                                    selectedOffer.carClass.title + ' · ' +
                                    selectedOffer.getPrice(order.offersManager.options) || 0 + '₽'
                                }
                            </Typography>
                        </Grid>
                    )
                })
            ) : (
                <Grid item>
                    <Typography>
                        Выберите перевозчика
                    </Typography>
                </Grid>
            )}
        </Grid>
    )
}


interface IOrdersPanel {
    freeMembers: Array<DeliveryMember>
    addCar: (members: Array<DeliveryMember>) => void
    orders: Array<IDeliveryOrder>
    openOrderPanel: (targetOrder: IDeliveryOrder) => void
    open: () => void
    close: () => void
    isOpen: boolean
}

const OrdersPanel: FC<IOrdersPanel> = ({
                                           freeMembers,
                                           addCar,
                                           orders,
                                           openOrderPanel,
                                           open,
                                           close,
                                           isOpen
                                       }): ReactElement => {
    const classes = useStyles()

    const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
    const openModal = () => setIsOpenModal(true)
    const closeModal = () => setIsOpenModal(false)

    return (
        <>
            <ModalFreeMembers
                freeMembers={freeMembers}
                submit={addCar}
                isOpen={isOpenModal}
                close={closeModal}
            />
            <Curtain
                open={open}
                close={close}
                isOpen={isOpen}
            >
                <Grid
                    container item
                    className={classes.root}
                    direction="column"
                    wrap="nowrap"
                    justifyContent="space-between"
                >
                    <Grid
                        container item
                        className={classes.header}
                    >
                    </Grid>
                    <Grid
                        container item
                        // className={classes.body}
                        direction="column"
                        wrap="nowrap"
                        sx={{overflow: 'auto', bgcolor: 'white', height: '70vh'}}
                    >
                        {freeMembers.length > 0 && (
                            <Grid
                                container item
                                direction="column"
                                wrap="nowrap"
                                alignItems="center"
                                sx={{p: '6px', borderBottom: '1px solid #E3E3E3'}}
                            >
                                <Grid item>
                                    <Typography>
                                        Не распределены {freeMembers.length} сотрудника
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        onClick={openModal}
                                    >
                                        Добавить машину
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                        {orders.map((order: IDeliveryOrder, orderIndex: number) => {
                            return (
                                <ShortOrderCard
                                    key={`delivery-order-${orderIndex}`}
                                    order={order}
                                    openOrderPanel={() => openOrderPanel(order)}
                                    status={order.status}
                                />
                            )
                        })}
                    </Grid>
                </Grid>
            </Curtain>
        </>
    )
}

export default OrdersPanel