import React, {FC, ReactElement, useState} from "react"
import {Grid, TextField, Typography} from "@mui/material"

// @ts-ignore
import {QuickScore} from "quick-score"
import {CurtainBody, CurtainHeader, CurtainPanel, ICurtainPanel} from "../CurtainPanel"
import SearchIcon from "@mui/icons-material/Search"

interface IUserListPanel extends ICurtainPanel {
    employees: Array<Employee>
    selectedEmployee: Employee | null
    selectEmployee: (employee: Employee | null) => void
}

const UserListPanel: FC<IUserListPanel> = (props): ReactElement => {
    const {
        isOpen, open, close,
        employees, selectedEmployee, selectEmployee
    }: IUserListPanel = props

    const [employeeName, setEmployeeName] = useState<string>("")
    const qsEmployees = new QuickScore(employees, ["fio", "email"])
    const filteredEmployees = qsEmployees.search(employeeName).map((result: any) => result.item)

    return (
        <CurtainPanel
            open={open} close={close} isOpen={isOpen}
            iconContainerWidth="3.4rem"
            iconContainerHeight="2.7rem"
        >
            <CurtainHeader>
                <Grid
                    sx={{
                        bgcolor: 'primary.main',
                        "& .MuiInputBase-root": {
                            color: 'white',
                            fontWeight: 400
                        }
                    }}
                    item container
                    direction="column"
                >
                    <Grid item sx={{p: '5px 9px'}}>
                        <TextField
                            autoComplete='off'
                            placeholder="Введите имя"
                            value={employeeName}
                            onChange={(event) => setEmployeeName(event.target.value)}
                            variant="standard"
                            fullWidth
                            InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                    <SearchIcon sx={{mr: '5px'}} />
                                )
                            }}
                        />
                    </Grid>
                </Grid>
            </CurtainHeader>
            <CurtainBody height='30rem'>
                <Grid
                    container item
                    direction="column"
                    wrap="nowrap"
                    sx={{overflow: 'auto', height: '100%'}}
                >
                    {filteredEmployees.length === 0 ? (
                        <Grid item>
                            <Typography
                                variant="body2"
                                sx={{padding: '10px'}}
                            >
                                Сотрудники не найдены
                            </Typography>
                        </Grid>
                    ) : (
                        filteredEmployees.map((employee: Employee, index: number) => {
                            const isSelected = !!selectedEmployee && employee.id === selectedEmployee.id
                            const typographyProps = isSelected ? {color: "common.white"} : {}
                            return (
                                <Grid
                                    item key={`employee-${employee.id}-${index}`}
                                    onClick={() => {
                                        selectEmployee(employee)
                                        close()
                                        // if (isSelected) {
                                        //     selectEmployee(null)
                                        // } else {
                                        //     selectEmployee(employee)
                                        // }
                                    }}
                                    sx={isSelected ? {bgcolor: 'primary.main'} : {}}
                                >
                                    <Typography
                                        variant="body2"
                                        sx={{padding: '10px'}}
                                        {...typographyProps}
                                    >
                                        {employee.fio || employee.email}
                                    </Typography>
                                </Grid>
                            )
                        })
                    )}
                    <Grid item sx={{minHeight: '3.4rem'}} />
                </Grid>
            </CurtainBody>
        </CurtainPanel>
    )
}

export default UserListPanel