import React, {FC, ReactElement, useEffect, useState} from "react"
import {useCreatedOrders, useNewOrder, useOrdersRequest, useProfile} from "@kashalot-web/react/dist"
import {GET_CREATED_ORDERS_REQ_ID} from "../../../requestIDs"
import {Box, Grid, TextField} from "@mui/material"
import NavigationButtons from "../../../LocalUICore/desktop/MainPageBlocks/NavigationButtons"
import SecondaryOrders from "../../../LocalUICore/desktop/MainPageBlocks/SecondaryOrders"
import {useNavigate} from "react-router-dom"
import Clock from "../../../LocalUICore/desktop/Clock"
import OrdersOutput from "../../../LocalUICore/desktop/MainPageBlocks/OrdersOutput"
import searchOrders from "../../../LocalUICore/common/SearchOrdersHook"
import share from "../../../LocalUICore/common/share"
import SearchIcon from "@mui/icons-material/Search"


const CANCEL_ORDER_REQ_ID = "main-page-cancel-order-req-id"


const MainPageTablet: FC = (): ReactElement => {
    const navigate = useNavigate()

    const {
        activeOrders, completedOrders, canceledOrders,
        cancelOrder
    } = useCreatedOrders()
    const {logout, userInfo} = useProfile()
    const {setReferenceOrder} = useNewOrder()

    const [actor] = useState<"client" | "dispatcher">(userInfo?.dispatcherID ? "dispatcher" : "client")

    const [gettingOrdersStatus] = useOrdersRequest(GET_CREATED_ORDERS_REQ_ID)

    const [searchValue, setSearchValue] = useState<string>("")

    return (
        <Grid
            container wrap="nowrap" justifyContent={"space-between"}
            sx={{bgcolor: 'primary.light', height: '100vh'}}
        >

            <Grid
                item container
                sx={{width: '6vw', pt: '3vh', pb: '12vh'}}
            >
                <NavigationButtons logout={logout} navigate={path => navigate(path)}/>
            </Grid>

            <Grid
                item container direction={"column"} wrap={"nowrap"}
                sx={{width: '54vw', p: '3vh 1vw', height: '100%'}}
            >
                <Grid item>
                    <TextField
                        name={"orders-search-input"}
                        fullWidth
                        placeholder="Вводите запрос"
                        value={searchValue}
                        onChange={e => setSearchValue(e.target.value)}
                        InputProps={{
                            sx: {bgcolor: 'white'},
                            endAdornment: (
                                <SearchIcon sx={{color: "lightgray"}}/>
                            )
                        }}
                    />
                </Grid>
                <Grid item container sx={{minHeight: '18px', maxHeight: '18px', height: '18px'}}/>
                <OrdersOutput
                    actor={actor}
                    orders={searchOrders(activeOrders, searchValue)}
                    loading={gettingOrdersStatus === "pending"}
                    orderCardsType={"full"}
                    share={order => share(order)}
                    cancel={orderID => cancelOrder(orderID, CANCEL_ORDER_REQ_ID)}
                    replay={order => {
                        setReferenceOrder(order)
                        navigate('/create_order')
                    }}
                />
            </Grid>

            <Grid
                item container
                sx={{
                    bgcolor: 'white',
                    width: '40vw',
                    p: '3vh 1vw'}}
            >
                <SecondaryOrders
                    actor={actor}
                    completedOrders={searchOrders(completedOrders, searchValue)}
                    canceledOrders={searchOrders(canceledOrders, searchValue)}
                    loading={gettingOrdersStatus === "pending"}
                    onReplay={order => {
                        setReferenceOrder(order)
                        navigate('/create_order')
                    }}
                />
            </Grid>

        </Grid>
    )
}

export default MainPageTablet