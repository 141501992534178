import {FC, ReactElement} from "react"
import MemberInformation from "../../../../LocalUICore/desktop/NewShippingBlocks/MemberInformation"
import {useMap, useNewShipping} from "@kashalot-web/react/dist"

const MembersBlockContainer: FC = (): ReactElement => {
    const {shipping, editMemberByIndex} = useNewShipping()
    const mapActions = useMap()

    return (
        <>
            <MemberInformation
                member={shipping.orderDetails.members[0]}
                onMemberChange={newMember => editMemberByIndex(0, newMember)}
                {...mapActions}
            />
            <MemberInformation
                member={shipping.orderDetails.members[1]}
                onMemberChange={newMember => editMemberByIndex(1, newMember)}
                {...mapActions}
            />
        </>
    )
}

export default MembersBlockContainer