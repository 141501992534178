import React, {FC, PropsWithChildren, ReactElement, useState} from "react"
import {Button, Divider, Fab, Grid, Typography} from "@mui/material"
import Dot from "../../common/Dot"
import {CurtainBody, CurtainPanel, ICurtainPanel} from "../CurtainPanel"
import Statuses from "@kashalot-web/core/dist/TaxiInfo/Statuses"
import moment from "moment"
import ReplyIcon from '@mui/icons-material/Reply'
import CloseIcon from '@mui/icons-material/Close'
import ReplayIcon from '@mui/icons-material/Replay'
import {OverridableComponent} from "@mui/material/OverridableComponent"
import {useNewOrder} from "@kashalot-web/react"
import {useNavigate} from "react-router-dom"

const ItemContainer: FC<PropsWithChildren<unknown>> = ({children}): ReactElement => {
    return (
        <Grid
            item container
            sx={{p: '0 0.3rem', m: '0.35rem 0'}}
            direction="column"
            wrap="nowrap"
        >
            {children}
        </Grid>
    )
}

const DividerContainer: FC = (): ReactElement => {
    return (
        <Divider sx={{m: '0.35rem 0'}}/>
    )
}

const Point: FC<{label: string}> = ({label}): ReactElement => {
    return (
        <Grid
            container justifyContent={"center"} alignItems={"center"} item
            sx={{width: '30px', height: '30px', borderRadius: "50px", bgcolor: "primary.main", mr: "10px"}}
        ><Typography color="white" variant={"h5"}>{label}</Typography></Grid>
    )
}

interface InstructionChangeRoute extends ICurtainPanel {
    order: CreatedOrder
}

const InstructionChangeRoute: FC<InstructionChangeRoute> = ({order, ...curtainProps}): ReactElement => {
    return (
        <CurtainPanel {...curtainProps}>
            <CurtainBody>
                <ItemContainer>
                    <Grid container item alignItems={"center"} wrap={"nowrap"}>
                        <Point label={"1"}/>
                        <Grid item>
                            Позвонить в таксопарк по номеру:
                            <br/>
                            <a href={`tel:${order.vendor.phone}`}>{order.vendor.phone}</a>
                        </Grid>
                    </Grid>
                </ItemContainer>
                <ItemContainer>
                    <Grid container item alignItems={"center"} wrap={"nowrap"}>
                        <Point label={"2"}/>
                        <Grid item>
                            Назвать номер телефона заказа:
                            <br/>
                            {order.customerPhoneNumber}
                        </Grid>
                    </Grid>
                </ItemContainer>
                <ItemContainer>
                    <Grid container item alignItems={"center"} wrap={"nowrap"}>
                        <Point label={"3"}/>
                        <Grid item>
                            Сообщить требуемые изменения
                        </Grid>
                    </Grid>
                </ItemContainer>
            </CurtainBody>
        </CurtainPanel>
    )
}

interface IButtonIcon {
    title: string
    Icon: OverridableComponent<any>
    onClick: () => void
}

const ButtonIcon: FC<IButtonIcon> = ({Icon, title, onClick}): ReactElement => {
    return (
        <Grid item container direction="column" wrap="nowrap" justifyContent="center" alignItems="center">
            <Fab color="primary" onClick={onClick} sx={{mb: '5px'}}>
                <Icon sx={{transform: 'scaleX(-1)'}}/>
            </Fab>
            <Typography>
                {title}
            </Typography>
        </Grid>
    )
}

interface IOrderPanel extends ICurtainPanel {
    order: CreatedOrder | null
    validatorForCanceling: (order: CreatedOrder) => boolean
    cancelOrder: (order: CreatedOrder) => void
}

const OrderPanel: FC<IOrderPanel> = (props): ReactElement => {
    const {order, validatorForCanceling, cancelOrder, ...curtainProps} = props
    const newOrder = useNewOrder()
    const navigate = useNavigate()

    const [isOpenInstruction, setIsOpenInstruction] = useState<boolean>(false)
    const openInstruction = () => setIsOpenInstruction(true)
    const closeInstruction = () => setIsOpenInstruction(false)

    let jamsTime: number = 0
    if (order && order.status.id === Statuses.Status8.id) {
        if (order.auto && order.auto.jamsTime) {
            jamsTime = Math.floor(order.auto.jamsTime / 60)
        } else {
            const minutes = Math.floor((order.auto?.jamsTime ||
                moment.duration(moment(order.deadline, "HH:mm DD.MM.YYYY").diff(moment())).asSeconds()) / 60)
            if (minutes > 0) {
                jamsTime = minutes
            }
        }
    }

    const share = () => {
        if (!order) {
            return
        }
        if (navigator.share) {
            navigator.share({
                title: order.title,
                text: '',
                url: 'https://kashalot.me/order/' + order.link,
            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error))
        } else {
            console.log('Share not supported on this browser, do it the old way.')
        }
    }

    const replayOrder = async () => {
        if (order) {
            newOrder.setReferenceOrder(order)
            navigate('/create_order')
        }
    }

    return (
        <>
            {order && (
                <InstructionChangeRoute
                    open={openInstruction}
                    close={closeInstruction}
                    isOpen={isOpenInstruction}
                    order={order}
                />
            )}
            <CurtainPanel {...curtainProps}>
                <CurtainBody>
                    {order && (
                        <Grid
                            container
                            direction="column"
                            wrap="nowrap"
                            sx={{bgcolor: 'white', p: '0 0.7rem'}}
                        >
                            <ItemContainer>
                                <Grid item container wrap="nowrap">
                                    <Grid item container direction="column" wrap="nowrap">
                                        <Typography variant="subtitle1">{order.deadline}</Typography>
                                        <Typography
                                            variant="body1"> {order.title}, {order.customerPhoneNumber} </Typography>
                                    </Grid>
                                </Grid>
                            </ItemContainer>

                            <DividerContainer/>

                            <ItemContainer>
                                <Grid item container alignItems="center" wrap="nowrap">
                                    <Grid item>
                                        <Typography variant="h3">
                                            {order.status.id === Statuses.Status8.id ? (
                                                `Через ~ ${jamsTime} мин`
                                            ) : (
                                                order.status.title
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </ItemContainer>
                            {order.auto && (
                                <>
                                    <ItemContainer>
                                        <Grid
                                            item
                                            container
                                            wrap="nowrap"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Grid
                                                item container direction="column" wrap="nowrap"
                                            >
                                                <Typography>
                                                    {order.auto.brand} {order.auto.model} · {order.auto.color}
                                                </Typography>
                                                {order.auto.driverPhone && (
                                                    <Typography>
                                                        Водитель: <a
                                                        href={`tel:${order.auto.driverPhone}`}>{order.auto.driverPhone}</a>
                                                    </Typography>
                                                )}
                                            </Grid>
                                            <Typography
                                                variant="body1"
                                                color="white"
                                                sx={{
                                                    bgcolor: (theme) => theme.palette.primary.dark,
                                                    p: '0.3rem',
                                                    borderRadius: '0.5rem', minWidth: '5.2em'
                                                }}
                                                noWrap={true}
                                                align="center"
                                            >
                                                {
                                                    order.auto.number.slice(0, 1) + " " +
                                                    order.auto.number.slice(1, 4) + " " +
                                                    order.auto.number.slice(4, 6)
                                                }
                                            </Typography>
                                        </Grid>
                                    </ItemContainer>
                                </>
                            )}

                            <DividerContainer/>

                            <ItemContainer>
                                <Grid
                                    item
                                    container
                                    wrap="nowrap"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Grid item container>
                                        <Typography>
                                            {order.vendor.name} · {order.carClass.title}
                                        </Typography>
                                    </Grid>
                                    {order.vendor.phone && (
                                        <Grid item container justifyContent="flex-end" alignItems="center"
                                              wrap="nowrap">
                                            <Typography>
                                                Диспетчер: <br/> <a
                                                href={`tel:${order.vendor.phone}`}>{order.vendor.phone}</a>
                                            </Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            </ItemContainer>

                            <ItemContainer>
                                <Grid
                                    item
                                    container
                                    wrap="nowrap"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Typography>
                                        {order.paymentType.title}
                                    </Typography>
                                    <Typography variant="body1">
                                        {order.price}₽
                                    </Typography>
                                </Grid>
                            </ItemContainer>

                            <DividerContainer/>

                            <ItemContainer>
                                {order.route.map((address: string, routePointIndex: number) => {
                                    return (
                                        <Grid
                                            key={`delivery-order-route-point-${routePointIndex}`}
                                            item container
                                            alignItems="center"
                                        >
                                            <Grid item sx={{mr: '7px'}}><Dot size="14px"/></Grid>
                                            <Grid item>
                                                <Typography variant="body2">
                                                    {address.split(',').filter((_, i) => i < 2).join(',')}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                            </ItemContainer>

                            <DividerContainer/>

                            <ItemContainer>
                                <Grid item container justifyContent="center">
                                    <Button onClick={openInstruction}>
                                        Изменить адрес
                                    </Button>
                                </Grid>
                            </ItemContainer>

                            <DividerContainer/>

                            <ItemContainer>
                                <Grid item container justifyContent="space-around" wrap="nowrap">
                                    {validatorForCanceling(order) && (
                                        <ButtonIcon
                                            onClick={() => cancelOrder(order)}
                                            Icon={CloseIcon}
                                            title="Отменить"
                                        />
                                    )}
                                    <ButtonIcon
                                        onClick={share}
                                        Icon={ReplyIcon}
                                        title="Поделиться"
                                    />
                                    <ButtonIcon
                                        onClick={replayOrder}
                                        Icon={ReplayIcon}
                                        title="Повторить"
                                    />
                                </Grid>
                            </ItemContainer>
                        </Grid>
                    )}
                </CurtainBody>
            </CurtainPanel>
        </>
    )
}

export default OrderPanel