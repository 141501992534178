import React, {FC, PropsWithChildren, ReactElement} from "react"
import { Divider, Grid, Typography} from "@mui/material"
import Dot from "../../common/Dot"
import Statuses from "@kashalot-web/core/dist/TaxiInfo/Statuses"
import moment from "moment"
import ShareIcon from '@mui/icons-material/Share'

const ItemContainer: FC<PropsWithChildren<unknown>> = ({children}): ReactElement => {
    return (
        <Grid
            item container
            sx={{p: '0 0.3rem', m: '0.35rem 0'}}
            direction="column"
            wrap="nowrap"
        >
            {children}
        </Grid>
    )
}

const DividerContainer: FC = (): ReactElement => {
    return (
        <Divider sx={{m: '0.35rem 0'}}/>
    )
}

interface IShortOrderPanel {
    order: CreatedOrder
}

const ShortOrderPanel: FC<IShortOrderPanel> = (props): ReactElement => {
    const {order} = props

    const share = () => {
        if (navigator.share && order) {
            navigator.share({
                title: order.title,
                text: '',
                url: 'https://kashalot.me/order/' + order.link,
            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error))
        } else {
            console.log('Share not supported on this browser, do it the old way.')
        }
    }

    return (
                <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    sx={{bgcolor: 'white', p: '0 0.7rem'}}
                >
                    <ItemContainer>
                        <Grid item container wrap="nowrap" justifyContent="space-between" alignItems="center">
                            <Grid item container direction="column" wrap="nowrap">
                                <Typography variant="subtitle1">{order.deadline}</Typography>
                                <Typography variant="body1"> {order.title} </Typography>
                            </Grid>
                            {order.link && (
                                <Grid item onClick={share}>
                                    <ShareIcon sx={{width: '2.2rem', height: '2.2rem'}} color="primary"/>
                                </Grid>
                            )}
                        </Grid>
                    </ItemContainer>

                    <DividerContainer/>

                    <ItemContainer>
                        <Grid item container justifyContent="space-between" alignItems="center" wrap="nowrap">
                            <Grid item>
                                <Typography variant="h4">
                                    {order.status.id === Statuses.Status8.id ? (
                                        `Через ~${
                                            Math.floor((order.auto?.jamsTime ||
                                                moment.duration(moment(order.deadline, "HH:mm DD.MM.YYYY").diff(moment())).asMilliseconds()) / 60)
                                        }`
                                    ) : (
                                        order.status.title
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                    </ItemContainer>
                    {order.auto && (
                        <>
                            <ItemContainer>
                                <Grid
                                    item
                                    container
                                    wrap="nowrap"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Grid
                                        item container direction="column" wrap="nowrap"
                                    >
                                        <Typography>
                                            {order.auto.brand} {order.auto.model} · {order.auto.color}
                                        </Typography>
                                        {order.auto.driverPhone && (
                                            <Typography>
                                                Водитель: <a
                                                href={`tel:${order.auto.driverPhone}`}>{order.auto.driverPhone}</a>
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Typography
                                        variant="body1"
                                        color="white"
                                        sx={{
                                            bgcolor: (theme) => theme.palette.primary.dark,
                                            p: '0.3rem',
                                            borderRadius: '0.5rem', minWidth: '5.2em'
                                        }}
                                        noWrap={true}
                                        align="center"
                                    >
                                        {
                                            order.auto.number.slice(0, 1) + " " +
                                            order.auto.number.slice(1, 4) + " " +
                                            order.auto.number.slice(4, 6)
                                        }
                                    </Typography>
                                </Grid>
                            </ItemContainer>
                        </>
                    )}

                    <DividerContainer/>

                    <ItemContainer>
                        <Grid
                            item
                            container
                            wrap="nowrap"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid item container>
                                <Typography>
                                    {order.vendor.name} · {order.carClass.title}
                                </Typography>
                            </Grid>
                            {order.vendor.phone && (
                                <Grid item container justifyContent="flex-end" alignItems="center"  wrap="nowrap">
                                    <Typography >
                                        Диспетчер: <br /> <a
                                        href={`tel:${order.vendor.phone}`}>{order.vendor.phone}</a>
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </ItemContainer>

                    <ItemContainer>
                        <Grid
                            item
                            container
                            wrap="nowrap"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Typography>
                                {order.paymentType.title}
                            </Typography>
                        </Grid>
                    </ItemContainer>

                    <DividerContainer/>

                    <ItemContainer>
                        {order.route.map((address: string, routePointIndex: number) => {
                            return (
                                <Grid
                                    key={`delivery-order-route-point-${routePointIndex}`}
                                    item container
                                    alignItems="center"
                                    sx={{p: '2px 0'}}
                                >
                                    <Grid item sx={{mr: '7px'}}><Dot size="17px"/></Grid>
                                    <Grid item>
                                        <Typography variant="body2">
                                            {address.split(',').filter((_, i) => i < 2).join(',')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </ItemContainer>
                </Grid>
    )
}

export default ShortOrderPanel