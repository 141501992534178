import React, {FC, ReactElement} from "react"
import {Grid} from "@mui/material"
import {BlockContainerWithSidePaddings} from "../../../LocalUICore/desktop/BlockContainer"
import TitleBlock from "../../../LocalUICore/desktop/NewOrderBlocks/TitleBlock"
import {useNavigate} from "react-router-dom"
import MembersBlockContainer from "./ContainerBlocks/MembersBlockContainer"
import CompanyEmployeeBlockContainer from "./ContainerBlocks/CompanyEmployeeBlockContainer"
import EmployeeBlockContainer from "./ContainerBlocks/EmployeeBlockContainer"
import {useProfile} from "@kashalot-web/react/dist"
import EmployeeTypes from "@kashalot-web/core/dist/TaxiInfo/EmployeeTypes"
import OffersBlockContainer from "./ContainerBlocks/OffersBlockContainer"
import CourierBlockContainer from "./ContainerBlocks/CourierBlockContainer";

const CreateShippingDesktop: FC = (): ReactElement => {
    const navigate = useNavigate()
    const {userInfo} = useProfile()

    return (
        <Grid container direction={"column"} wrap={"nowrap"}>
            <BlockContainerWithSidePaddings
                item container
                direction={"column"} wrap={"nowrap"}
                sx={{bgcolor: 'primary.dark'}}
            >
                <TitleBlock title={"Создание доставки"} close={() => navigate('/')}/>
                {userInfo
                && [EmployeeTypes.Dispatcher.id, EmployeeTypes.DispatcherWorker.id].includes(userInfo.employeeType.id)
                && (
                    <CompanyEmployeeBlockContainer/>
                )}
                {userInfo
                && [...EmployeeTypes.getBranchEmployees(), ...EmployeeTypes.getCompanyEmployees()]
                    .map(e => e.id).includes(userInfo.employeeType.id)
                && (
                    <EmployeeBlockContainer/>
                )}
            </BlockContainerWithSidePaddings>

            <BlockContainerWithSidePaddings
                item container
                direction={"column"} wrap={"nowrap"}
            >
                <MembersBlockContainer/>
            </BlockContainerWithSidePaddings>

            <BlockContainerWithSidePaddings
                item container
                direction={"column"} wrap={"nowrap"}
            >
                <CourierBlockContainer/>
            </BlockContainerWithSidePaddings>

            <BlockContainerWithSidePaddings
                item container
                direction={"column"} wrap={"nowrap"}
                sx={{bgcolor: 'primary.light'}}
            >
                <OffersBlockContainer/>
            </BlockContainerWithSidePaddings>
        </Grid>
    )
}

export default CreateShippingDesktop