import React, {FC, ReactElement, Fragment} from "react"
import {Box, Divider, Grid, TextField} from "@mui/material"
import AddBoxIcon from "@mui/icons-material/AddBox"
import BackspaceIcon from "@mui/icons-material/Backspace"
import MapIcon from '@mui/icons-material/Map'

interface IRoutPointInput {
    value: string
    onChange: (newInputValue: string) => void
    placeholder: string
    onFocus: () => void
    onBlur: () => void
    addField?: () => void
    deleteField?: () => void
    goToMap?: () => void
}

const RoutPointInput: FC<IRoutPointInput> = (props): ReactElement => {
    const {value, onChange, placeholder, onFocus, onBlur, addField, deleteField, goToMap} = props

    return (
        <Grid
            item container
            wrap={"nowrap"}
            justifyContent={"space-between"}
            alignItems={"center"}
        >
            <Box sx={{flexGrow: 3}}>
                <TextField
                    value={value}
                    onChange={event => onChange(event.target.value)}
                    placeholder={placeholder}
                    onBlur={onBlur}
                    onFocus={onFocus}

                    autoComplete='off'
                    variant="standard"
                    fullWidth
                    InputProps={{disableUnderline: true}}
                />
            </Box>
            <Box sx={{display: 'flex', flexGrow: 0, flexWrap: 'nowrap'}}>
                {[
                    {icon: BackspaceIcon, onClick: deleteField},
                    {icon: AddBoxIcon, onClick: addField},
                    {icon: MapIcon, onClick: goToMap}
                ].map((item, ind) => {
                    if (item.onClick) {
                        return (
                            <Grid item onClick={item.onClick} sx={{ml: '8px'}} key={`com-${ind}`}>
                                <item.icon sx={{color: 'white', verticalAlign: 'middle', fontSize: '29px'}}/>
                            </Grid>
                        )
                    }
                })}
            </Box>
        </Grid>
    )
}

export default RoutPointInput