import React, {FC, ReactElement, useEffect, useState} from "react"
import FullScreenContainer from "../../../LocalUICore/common/FullScreenContainer"
import HeaderRoute from "../../../LocalUICore/mobile/HeaderRoute"
import {useDispatcher, useMap, useNewDelivery, useOrganization, useProfile} from "@kashalot-web/react/dist"
import {Backdrop, CircularProgress, Grid, Snackbar, Typography} from "@mui/material"
import IconButtonWithLabel from "../../../LocalUICore/mobile/IconButtonWithLabel"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import MainButton from "../../../LocalUICore/mobile/MainButton"
import {useNavigate} from "react-router-dom"
import PersonIcon from "@mui/icons-material/Person"
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi'
import CommonDetailsPanelContainer from "./PanelsContainers/CommonDetailsPanelContainer"
import OrdersPanelContainer from "./PanelsContainers/OrdersPanelContainer"
import DeadlinePanelContainer from "./PanelsContainers/DeadlinePanelContainer"
import EmployeesPanelContainer from "./PanelsContainers/EmployeesPanelContainer"
import BackdropContainer from "../../../LocalUICore/common/BackdropContainer"
import YMapsBG from "../../../LocalUICore/mobile/IMapsBG"
import useCenter from "../../useCenter"
import {makeID} from '@kashalot-web/core/dist/Utils/config'
import ApartmentIcon from '@mui/icons-material/Apartment'
import OrganizationsPanelContainer from "./PanelsContainers/OrganizationsPanelContainer"
import AutoModeIcon from "@mui/icons-material/AutoMode";
import DelayedOptionsPanelContainer from "./PanelsContainers/DelayedOptionsPanelContainer";


const useEmployeesWishlists = (employeesManager: IEmployeesManager | null) => {
    const {getCoordinatesByAddress, coordinatesRequests, removeCoordinatesRequest} = useMap()

    const [localRequests, setLocalRequests] = useState<Array<{ id: string, employee: Employee, address: string }>>([])
    const [points, setPoints] = useState<Array<{coordinates: [number, number], employee: Employee, address: string}>>([])

    useEffect(() => {
        const requests: Array<{ id: string, employee: Employee, address: string }> = []
        if (employeesManager) {
            employeesManager.getActiveEmployees().map(employee => {
                const home = employee.wishlist?.find(wishlistItem => wishlistItem.type === "home") || null
                if (home) {
                    const reqID = "delivery-request-address-" + makeID(8)
                    getCoordinatesByAddress(reqID, home.address)
                    requests.push({id: reqID, employee: employee, address: home.address})
                }
            })
        }
        setLocalRequests(requests)
        setPoints([])
    }, [employeesManager])

    useEffect(() => {
        const requestIDsForDelete: string[] = []
        const newPoints: Array<{coordinates: [number, number], employee: Employee, address: string}> = []
        localRequests.forEach(localRequest => {
            const req = coordinatesRequests.find(reqIT => reqIT.id === localRequest.id)
            if (req?.status === "success" || req?.status === "error") {
                if (req.status === "success" && req.coordinates) {
                    newPoints.push({
                        coordinates: req.coordinates,
                        employee: localRequest.employee,
                        address: localRequest.address
                    })
                }
                removeCoordinatesRequest(req.id)
                requestIDsForDelete.push(req.id)
            }
        })
        setLocalRequests(rs => rs.filter(r => !requestIDsForDelete.find(rfd => rfd === r.id)))
        setPoints(p => [...p, ...newPoints])
    }, [coordinatesRequests])

    return points
}

const WORK_POINT_REQ_ID = makeID(8)
const useWorkPoint = (wishlist: Array<WishlistItem> | null) => {
    const {getCoordinatesByAddress, coordinatesRequests, removeCoordinatesRequest} = useMap()
    const [coordinates, setCoordinates] = useState<[number, number] | null>(null)

    useEffect(() => {
        const work = wishlist?.find(item => item.type === "work") || null
        if (work) {
            getCoordinatesByAddress(WORK_POINT_REQ_ID, work.address)
        }
    }, [wishlist])

    useEffect(() => {
        const request = coordinatesRequests.find(r => r.id === WORK_POINT_REQ_ID)
        if (request && (request.status === "success" || request.status === "error")) {
            if (request.status === "success") {
                setCoordinates(request.coordinates)
            }
            removeCoordinatesRequest(WORK_POINT_REQ_ID)
        }
    }, [coordinatesRequests])

    return coordinates
}


enum Panels {Main, Organizations, EmployeesList, CommonDetails, Date, Orders, Delayed}

const getShortAddress = (address: string) => address.split(',').filter((a, i) => i < 2).join(',')

const CreateDeliveryMobile: FC = (): ReactElement => {
    const navigate = useNavigate()

    const {delivery, calcRoutes, map, setMap, doDelivery, resetDelivery, updateMember, removeMember} = useNewDelivery()
    const {userInfo} = useProfile()

    const defaultCenter = useCenter()

    const workCoordinates = useWorkPoint(userInfo?.wishlist || null)

    const [panel, openPanel] = useState<keyof Record<Panels, string>>(Panels.Main)

    const {employeesManager} = useOrganization()
    const points = useEmployeesWishlists(employeesManager)
    const [pointsForMap, setPointsForMap] = useState<Array<{
        coordinates: [number, number],
        type: "home" | "work" | "employee_home",
        onClick?: () => void,
        placeholder?: string,
        active?: boolean
    }>>([])
    useEffect(() => {
        const newPointsForMap: Array<{
            coordinates: [number, number],
            type: "home" | "work" | "employee_home",
            onClick?: () => void,
            placeholder?: string,
            active?: boolean
        }> = points.map(p => {
            const isAdded = delivery.members.find(m => m.id === p.employee.id)
            const placeholder = (!!isAdded ? isAdded.amount.toString() + 'x ' : '') + (p.employee.fio || p.employee.email)
            return {
                coordinates: p.coordinates,
                type: "employee_home",
                placeholder: placeholder,
                onClick: () => {
                    if (isAdded) {
                        removeMember(isAdded)
                    } else {
                        updateMember({
                            ...p.employee,
                            address: p.address,
                            coordinates: p.coordinates,
                            amount: 1,
                            isNew: false,
                            key: makeID(10)
                        })
                    }
                },
                active: !!isAdded
            }
        })
        delivery.members.forEach(member => {
            const isInclude = points.map(point => point.employee.id).includes(member.id)
            if (!isInclude && member.coordinates) {
                newPointsForMap.push({
                    coordinates: member.coordinates,
                    type: "employee_home",
                    active: true,
                    onClick: () => removeMember(member),
                    placeholder: `${member.amount}x ${member.fio || member.email}`
                })
            }
        })
        if (workCoordinates) {
            newPointsForMap.push({type: "work", coordinates: workCoordinates})
        }
        setPointsForMap(newPointsForMap)
    }, [points, delivery.members, workCoordinates])


    const [simpleLoading, setSimpleLoading] = useState<boolean>(false)
    const [ordering, setOrdering] = useState<boolean>(false)
    const [calcing, setCalcing] = useState<boolean>(false)
    const [snackbarOrderingError, setSnackbarOrderingError] = useState<boolean>(false)
    const closeSnackbarOrderingError = () => setSnackbarOrderingError(false)


    useEffect(() => {
        if (delivery.status === "filling" && calcing) {
            openPanel(Panels.Orders)
        }
        if (delivery.status === "error" && ordering) {
            openPanel(Panels.Orders)
            setSnackbarOrderingError(true)
        }

        setSimpleLoading(delivery.status === "loading")
        setCalcing(delivery.status === "calcing")
        setOrdering(delivery.status === "ordering")

        const redirect = !!delivery.orders && delivery.orders.length > 0
            && delivery.orders.reduce((acc, order) => order.status === "success" && acc, true)
        if (redirect) {
            resetDelivery()
            navigate('/')
        }
    }, [delivery])


    return (
        <FullScreenContainer>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={simpleLoading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <BackdropContainer open={calcing} container item wrap={"nowrap"} direction={"column"} alignItems={"center"} sx={{mt: "20px"}}>
                {delivery.members.length > 6 && (
                    <>
                        <Typography>Идет расчет маршрутов,</Typography>
                        <Typography>это может занять больше минуты</Typography>
                    </>
                )}
            </BackdropContainer>
            <BackdropContainer open={ordering} container item wrap={"nowrap"} direction={"row"} justifyContent={"center"} sx={{mt: "20px"}}>
                {delivery.orders ? delivery.orders.map((order, index) => {
                    let color = "info.light"
                    if (order.status === "error") {
                        color = "error.light"
                    }
                    if (order.status === "success") {
                        color = "success.light"
                    }
                    return (
                        <Grid
                            key={`order-status-${index}`}
                            sx={{bgcolor: color, width: '30px', height: '30px', borderRadius: "20px", m: "4px"}}
                            item
                        />
                    )
                }) : null}
            </BackdropContainer>

            <Snackbar
                open={snackbarOrderingError}
                onClose={closeSnackbarOrderingError}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                ContentProps={{sx: {bgcolor: 'white'}}}
                message={<Typography color={"error.dark"}>Ошибка! Попробуйте выбрать другого перевозчика</Typography>}
            />

            {defaultCenter ? (
                <YMapsBG
                    map={map} setMap={setMap}
                    defaultCenter={defaultCenter}
                    isActive={false}
                    onCenterChange={() => 42}
                    resetAddress={() => 42}
                    points={pointsForMap}
                />
            ) : (
                <Grid
                    item container
                    sx={{height: '100%', bgcolor: 'lightgray'}}
                    direction={"column"} wrap={"nowrap"}
                    justifyContent={"center"} alignItems={"center"}
                >
                    <CircularProgress />
                    <Typography>Загрузка карты</Typography>
                </Grid>
            )}

            <HeaderRoute
                onClick={() => openPanel(Panels.CommonDetails)}
                title={"Адрес подачи:"}
                placeholder={"Указать адрес подачи"}
                labels={delivery.startingAddress ? [getShortAddress(delivery.startingAddress)] : []}
            />

            <Grid
                container
                sx={{position: "absolute", bottom: 0, pointerEvents: 'none'}}
                direction="column"
                wrap="nowrap"
            >
                <Grid
                    item container
                    wrap="nowrap"
                    direction="column"
                >
                    <IconButtonWithLabel
                        icon={<ArrowBackIcon/>}
                        onClick={() => navigate('/')}
                        labels={[]}
                    />
                    {userInfo && userInfo.dispatcherID && (
                        <IconButtonWithLabel
                            icon={<ApartmentIcon/>}
                            onClick={() => openPanel(Panels.Organizations)}
                            labels={!delivery.organization ? ["Компания"] : ([delivery.organization.name])}
                        />
                    )}
                    <IconButtonWithLabel
                        icon={<PersonIcon/>}
                        onClick={() => openPanel(Panels.EmployeesList)}
                        labels={delivery.members.length === 0 ? ["Кому"] : ([
                            ...delivery.members.slice(0, 3).map(member => (
                                <Grid item>
                                    <Grid container wrap={"nowrap"} alignItems={"center"}>
                                        <Typography variant={"body2"}>
                                            {member.fio || member.email}
                                        </Typography>
                                        &nbsp;
                                        <Typography variant={"h5"}>
                                            x{member.amount}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )),
                            ...(delivery.members.length > 3 ? [`+${delivery.members.length - 3}`] : [])
                        ])}
                    />
                    <IconButtonWithLabel
                        icon={<AutoModeIcon sx={{color: 'white'}}/>}
                        onClick={() => openPanel(Panels.Delayed)}
                        labels={[
                            ("Автоназначение"),
                            (delivery.delayed?.maxDelayTime ? delivery.delayed?.maxDelayTime + " мин" : "выкл")
                        ]}
                    />
                    <IconButtonWithLabel
                        icon={<LocalTaxiIcon/>}
                        onClick={() => openPanel(Panels.Orders)}
                        labels={["Маршруты"]}
                    />
                    {(() => {
                        const state = {disable: false, label: "", onClick: () => {}}
                        if (!delivery.startingAddress) {
                            state.label = "Указать адрес подачи"
                            state.onClick = () => openPanel(Panels.CommonDetails)
                        } else if (delivery.members.length === 0) {
                            state.label = "Выбрать сотрудников"
                            state.onClick = () => openPanel(Panels.EmployeesList)
                        } else if (delivery.validateForCalc() && !delivery.orders) {
                            state.label = "Рассчитать"
                            state.onClick = () => calcRoutes()
                        } else {
                            state.disable = !delivery.validate()
                            state.label = "Заказать"
                            state.onClick = () => doDelivery()
                        }
                        return (
                            <MainButton
                                disabled={state.disable}
                                onClick={state.onClick}
                                label={state.label}
                                onClickDate={() => openPanel(Panels.Date)}
                                // onClickPayment={() => 42}
                            />
                        )
                    })()}
                </Grid>
            </Grid>

            <OrganizationsPanelContainer
                isOpen={panel === Panels.Organizations}
                open={() => openPanel(Panels.Organizations)}
                close={() => openPanel(Panels.Main)}
            />
            <CommonDetailsPanelContainer
                isOpen={panel === Panels.CommonDetails}
                open={() => openPanel(Panels.CommonDetails)}
                close={() => openPanel(Panels.Main)}
            />
            <EmployeesPanelContainer
                isOpen={panel === Panels.EmployeesList}
                open={() => openPanel(Panels.EmployeesList)}
                close={() => openPanel(Panels.Main)}
            />
            <OrdersPanelContainer
                isOpen={panel === Panels.Orders}
                open={() => openPanel(Panels.Orders)}
                close={() => openPanel(Panels.Main)}
            />
            <DelayedOptionsPanelContainer
                isOpen={panel === Panels.Delayed}
                open={() => openPanel(Panels.Delayed)}
                close={() => openPanel(Panels.Main)}
            />
            <DeadlinePanelContainer
                isOpen={panel === Panels.Date}
                open={() => openPanel(Panels.Date)}
                close={() => openPanel(Panels.Main)}
            />
        </FullScreenContainer>
    )
}

export default CreateDeliveryMobile