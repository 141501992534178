import React, {FC, ReactElement, useEffect} from "react"
import {useMediaQuery, Theme} from "@mui/material"
import CreateOrderMobile from "./CreateOrderMobile"
import CreateOrderDesktop from "./CreateOrderDesktop"
import useNewOrder from "@kashalot-web/react/dist/useNewOrder"
import {useOrganization, useProfile} from "@kashalot-web/react"
import {GET_CLIENTS_REQ_ID, GET_EMPLOYEES_REQ_ID} from "../../requestIDs"
import EmployeeTypes from "@kashalot-web/core/dist/TaxiInfo/EmployeeTypes"
import {useDispatcher} from "@kashalot-web/react/dist"

const CreateOrderPage: FC = (): ReactElement => {
    const {userInfo} = useProfile()
    const {
        order,
        referenceOrder,
        startListeningPrices,
        editOrderDetail,
        stopListeningPrices,
        setOrderByReference
    } = useNewOrder()
    const {getEmployeesList, employeesManager} = useOrganization()
    const {getClients} = useDispatcher()

    useEffect(() => {
        setOrderByReference()
        if (userInfo) {
            if ([EmployeeTypes.Dispatcher.id, EmployeeTypes.DispatcherWorker.id].includes(userInfo.employeeType.id)) {
                getClients(GET_CLIENTS_REQ_ID)
            } else if ([...EmployeeTypes.getBranchEmployees(), ...EmployeeTypes.getCompanyEmployees()]
                .map(e => e.id).includes(userInfo.employeeType.id)) {
                getEmployeesList(GET_EMPLOYEES_REQ_ID)
            }
        }
        startListeningPrices()
        return () => stopListeningPrices()
    }, [])

    useEffect(() => {
        let candidate: Employee | null = null
        if (userInfo
            && [...EmployeeTypes.getBranchEmployees(), ...EmployeeTypes.getCompanyEmployees()].map(e => e.id).includes(userInfo.employeeType.id)
            && employeesManager && (!order.orderDetails.employee && !referenceOrder)) {
            candidate = employeesManager.getActiveEmployees().find(employee => employee.id === userInfo.userID) || null
            if (candidate) {
                editOrderDetail("employee", candidate)
                editOrderDetail("customerPhoneNumber", candidate.phone)
            }
        }
    }, [employeesManager])

    // const xl = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'))
    // const lg = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
    // const md = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))
    const sm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
    // const xs = useMediaQuery((theme: Theme) => theme.breakpoints.up('xs'))

    if (sm) {
        return (
            <CreateOrderDesktop/>
        )
    }

    return (
        <CreateOrderMobile/>
    )
}

export default CreateOrderPage