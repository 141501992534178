import React, {FC, ReactElement} from "react"
import {useProfile} from "@kashalot-web/react"
import {LoginPageSketch} from "@kashalot-web/ui/dist"

const LoginPage: FC = (): ReactElement => {
    return (
        <LoginPageSketch {...useProfile()} />
    )
}

export default LoginPage
