import {FC, ReactElement} from "react"
import OrdersBlock from "../../../../LocalUICore/desktop/NewDeliveryBlocks/OrdersBlock"
import {useNewDelivery} from "@kashalot-web/react/dist"
import TaxiClasses from '@kashalot-web/core/dist/TaxiInfo/TaxiClasses'

const OrdersBlockContainer: FC = (): ReactElement => {
    const {delivery, putMember, dropMember, addCar, swapOrderRoutePoints, selectDeliveryOffer, updateOrder, setOrderOptions} = useNewDelivery()

    if (delivery.orders) {
        return (
            <OrdersBlock
                orders={delivery.orders}
                freeMembers={delivery.freeMembers}
                dropMember={dropMember}
                addCar={addCar}
                swapOrderRoutePoints={swapOrderRoutePoints}
                putMember={putMember}
                selectDeliveryOffer={selectDeliveryOffer}
                allTaxiClasses={TaxiClasses.getAllClasses()}
                updateOrder={updateOrder}
                setOrderOptions={setOrderOptions}
            />
        )
    }

    return (
        <></>
    )
}

export default OrdersBlockContainer