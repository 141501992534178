import {FC, ReactElement} from "react"
import MainButtonBlock from "../../../../LocalUICore/desktop/NewDeliveryBlocks/MainButtonBlock"
import {useNewDelivery} from "@kashalot-web/react/dist"

const MainButtonBlockContainer: FC = (): ReactElement => {
    const {delivery, calcRoutes, doDelivery} = useNewDelivery()

    if (!delivery.orders) {
        return (
            <MainButtonBlock
                label={"Рассчитать"}
                onClick={calcRoutes}
                disabled={!delivery.validateForCalc()}
            />
        )
    }

    return (
        <MainButtonBlock
            label={`${delivery.orders.reduce((acc, curr) => 
                acc + (curr.offersManager.selectedOffers[0]?.getPrice(curr.offersManager.options) || 0), 0)}₽ • Заказать`}
            onClick={doDelivery}
            disabled={!delivery.validate()}
        />
    )
}

export default MainButtonBlockContainer