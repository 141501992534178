import {FC, ReactElement} from "react"
import {Grid, GridProps} from "@mui/material"

const BlockContainer: FC<GridProps> = ({children, sx, ...props}): ReactElement => {
    return (
        <Grid {...props} sx={{...sx, pt: '12px', pb: '12px'}}>
            {children}
        </Grid>
    )
}

export default BlockContainer

export const BlockContainerWithSidePaddings: FC<GridProps> = ({children, sx, ...props}): ReactElement => {
    return (
        <BlockContainer {...props} sx={{...sx, pl: '10vw', pr: '10vw'}}>
            {children}
        </BlockContainer>
    )
}