import {Theme} from "@mui/material"
import { makeStyles, createStyles } from '@mui/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // backgroundColor: 'white',
            height: '17vw'
        },
        logoContainer: {
            position: 'relative',
            width: '80%',
            height: '80%',
            borderRadius: '8vw',
            boxShadow: "0px 0px 4px -1px rgba(34, 60, 80, 0.2)",
        },
        logo: {
            width: '80%',
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)"
        },
    })
)