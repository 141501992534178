import {FC, ReactElement, useEffect} from "react"
import {useParams} from "react-router-dom"
import {useCreatedOrders} from "@kashalot-web/react/dist"
import ShortOrderPanel from "../../LocalUICore/mobile/OrderPanel/ShortOrderPanel"
import {Grid, Typography} from "@mui/material"

const GET_ORDER_BY_LINK_REQ_ID = "get-order0by0link-req-id-asmdf.2"

const OrderByLinkPage: FC = (): ReactElement => {
    const {link} = useParams<{link: string}>()
    const {getOrderByLink, orderByLink} = useCreatedOrders()

    useEffect(() => {
        if (link) {
            getOrderByLink(link, GET_ORDER_BY_LINK_REQ_ID)
        }
    }, [link])

    if (orderByLink) {
        return (
            <Grid container sx={{bgcolor: 'primary.dark'}}>
                <ShortOrderPanel order={orderByLink} />
            </Grid>
        )
    }

    return (
        <Grid item container sx={{height: '90vh'}} justifyContent="center" alignItems="center">
            <Typography variant="h2">Заказ не найден</Typography>
        </Grid>
    )
}

export default OrderByLinkPage