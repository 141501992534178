import React, {FC, ReactElement, useState} from "react"
import {Divider, Grid, Skeleton, Typography} from "@mui/material"
import {OfferCard} from "./OfferCards"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import OffersListPanel from "./OffersListPanel"

interface IOfferContainer {
    title: string
    offer: IOffer
    selectOffer: () => void
    isSelected: boolean
    arrowClick: () => void
}

const OfferContainer: FC<IOfferContainer> = (props): ReactElement => {
    const {offer, selectOffer, isSelected, title, arrowClick} = props

    return (
        <Grid
            item
            container
            direction="column"
            wrap="nowrap"
        >
            <Grid item sx={{p: '0.3rem 5vw'}}>
                <Typography variant="body1">
                    {title}
                </Typography>
            </Grid>
            <Grid
                item
                container
                justifyContent="space-between"
                wrap="nowrap"
            >
                <OfferCard
                    offer={offer}
                    onClick={selectOffer}
                    isSelected={isSelected}
                />
                <Grid item container justifyContent="center" alignItems="center" sx={{width: '30vw'}}
                      onClick={arrowClick}>
                    <ArrowForwardIosIcon color="primary" sx={{height: '1.7rem', width: '100%'}}/>
                </Grid>
            </Grid>
        </Grid>
    )
}

const SkeletonOfferCard: FC = (): ReactElement => {
    return (
        <Grid item container sx={{p: '18px 40px'}} wrap={"nowrap"} alignItems={"center"}>
            <Grid item sx={{mr: '8px'}}><Skeleton variant="circular" width={'16vw'} height={'16vw'} /></Grid>
            <Grid item container direction={"column"} wrap={"nowrap"}>
                <Skeleton variant="text" width={'150px'} />
                <Skeleton variant="text" width={'150px'} />
            </Grid>
        </Grid>
    )
}

interface IFastAndCheap {
    order: IOrder
    fastOffers: Array<IOffer>
    cheapOffers: Array<IOffer>
    selectedOffers: Array<IOffer>
    selectedOfferType: IOffersManager["selectedOfferType"]
    selectOffer: (offerID: string, type: IOffersManager["selectedOfferType"]) => void
    setOfferDelay: (offerID: string, delay: number) => void
    loading?: boolean
}

const FastAndCheap: FC<IFastAndCheap> = (props): ReactElement => {
    const {
        order,
        fastOffers, cheapOffers,
        selectedOffers, selectedOfferType, selectOffer, setOfferDelay,
        loading
    } = props

    const cheapestOffer =
        (selectedOfferType === "cheap" && cheapOffers.find(offer => selectedOffers.map(o => o.id).includes(offer.id))) ||
        cheapOffers[0]
    const fastestOffer =
        (selectedOfferType === "fast" && fastOffers.find(offer => selectedOffers.map(o => o.id).includes(offer.id))) ||
        fastOffers[0]

    const [isOpenOffersList, setIsOpenOffersList] = useState<boolean>(false)
    const [offersTypeList, setOffersTypeList] = useState<IOffersManager["selectedOfferType"]>("cheap")

    if (fastOffers.length === 0 && loading) {
        return (
            <Grid item container sx={{bgcolor: 'common.white'}} direction={"column"} wrap={"nowrap"}>
                <SkeletonOfferCard />
                <Divider/>
                <SkeletonOfferCard />
            </Grid>
        )
    }

    if (fastOffers.length === 0) {
        return (
            <Grid item container sx={{bgcolor: 'common.white', p: '4px 0'}} justifyContent={"center"}>
                <Typography align={"center"}>Ничего не найдено,</Typography>
                <br/>
                <Typography align={"center"}>попробуйте выбрать другой класс машины</Typography>
            </Grid>
        )
    }

    return (
        <>
            <OffersListPanel
                isOpen={isOpenOffersList}
                open={() => setIsOpenOffersList(true)}
                close={() => setIsOpenOffersList(false)}
                order={order}
                offers={offersTypeList === "fast" ? fastOffers : cheapOffers}
                selectOffer={(id: string) => selectOffer(id, offersTypeList)}
                selectedOffers={selectedOfferType === offersTypeList ? selectedOffers : []}
                title={offersTypeList === "fast" ? "Быстрые" : "Дешевые"}
                setOfferDelay={setOfferDelay}
            />
            <Grid
                item container
                direction="column"
                wrap="nowrap"
                sx={{bgcolor: 'common.white'}}
            >
                <OfferContainer
                    title="Быстрые"
                    offer={fastestOffer}
                    selectOffer={() => selectOffer(fastestOffer.id, "fast")}
                    isSelected={selectedOfferType === "fast" && selectedOffers.length > 0}
                    arrowClick={() => {
                        setOffersTypeList("fast")
                        setIsOpenOffersList(true)
                    }}
                />
                <Divider/>
                <OfferContainer
                    title="Дешевые"
                    offer={cheapestOffer}
                    selectOffer={() => selectOffer(cheapestOffer.id, "cheap")}
                    isSelected={selectedOfferType === "cheap" && selectedOffers.length > 0}
                    arrowClick={() => {
                        setOffersTypeList("cheap")
                        setIsOpenOffersList(true)
                    }}
                />
                <Divider/>
            </Grid>
        </>
    )
}

export default FastAndCheap