import React, {FC, ReactElement, ReactNode} from "react"
import {CurtainBody, CurtainPanel, ICurtainPanel} from "../CurtainPanel"
import {Grid, OutlinedInput, TextField, Typography} from "@mui/material"
import InputMask from "react-input-mask"

interface IUserDetailsPanel extends ICurtainPanel {
    organizationName?: string
    openOrganizationsList?: () => void
    employeeName: string
    openEmployeesList: () => void
    phoneNumber: string
    editPhoneNumber: (newPhoneNumber: string) => void
    orderTitle: string
    editOrderTitle: (newOrderTitle: string) => void
    comment: string
    editComment: (newComment: string) => void
}

const UserDetailsPanel: FC<IUserDetailsPanel> = (props): ReactElement => {
    const {
        organizationName, openOrganizationsList,
        employeeName, openEmployeesList,
        phoneNumber, editPhoneNumber,
        orderTitle, editOrderTitle,
        comment, editComment,
        ...curtainProps
    }: IUserDetailsPanel = props

    return (
        <CurtainPanel {...curtainProps}>
            <CurtainBody>
                <Grid
                    container item
                    direction="column"
                    wrap="nowrap"
                    sx={{p: '0.5rem 1rem'}}
                    spacing={1}
                >
                    {organizationName !== undefined && openOrganizationsList !== undefined && (
                        <Grid container item>
                            <Typography variant="subtitle1">
                                Компания
                            </Typography>
                            <OutlinedInput
                                fullWidth
                                placeholder="Выберите компанию"
                                value={organizationName}
                                onClick={openOrganizationsList}
                                readOnly={true}
                            />
                        </Grid>
                    )}
                    <Grid container item>
                        <Typography variant="subtitle1">
                            Сотрудник
                        </Typography>
                        <OutlinedInput
                            fullWidth
                            placeholder="Выберите сотрудника"
                            value={employeeName}
                            onClick={openEmployeesList}
                            readOnly={true}
                        />
                    </Grid>
                    <Grid container item>
                        <Typography variant="subtitle1">
                            Номер телефона
                        </Typography>
                        <InputMask
                            mask="+7 (999) 999-9999"
                            value={phoneNumber ? phoneNumber : "+"}
                            onChange={(event) => editPhoneNumber(event.target.value)}
                            children={((inputProps: any) => <OutlinedInput {...inputProps} type="tel" fullWidth/>) as unknown as ReactNode}
                        />
                            {/*{(inputProps: any) => <OutlinedInput {...inputProps} type="tel" fullWidth/>}*/}
                        {/*</InputMask>*/}
                    </Grid>
                    <Grid container item>
                        <Typography variant="subtitle1">
                            Название заказа
                        </Typography>
                        <OutlinedInput
                            fullWidth
                            value={orderTitle}
                            placeholder="Введите название заказа"
                            onChange={(event) => editOrderTitle(event.target.value)}
                        />
                    </Grid>
                    <Grid container item>
                        <Typography variant="subtitle1">
                            Комментарий
                        </Typography>
                        <OutlinedInput
                            fullWidth
                            value={comment}
                            placeholder="Введите комментарий"
                            onChange={(event) => editComment(event.target.value)}
                        />
                    </Grid>
                </Grid>
            </CurtainBody>
        </CurtainPanel>
    )
}

export default UserDetailsPanel