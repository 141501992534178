import {FC, ReactElement} from "react"
import PassengerBlock from "../../../../LocalUICore/desktop/NewOrderBlocks/PassengerBlock"
import {useNewOrder} from "@kashalot-web/react/dist"

const PassengerBlockContainer: FC = (): ReactElement => {
    const {order, editOrderDetail} = useNewOrder()

    return (
        <PassengerBlock
            name={order.orderDetails.customerName}
            editName={newName => editOrderDetail("customerName", newName)}
            phoneNumber={order.orderDetails.customerPhoneNumber}
            editPhoneNumber={newPhoneNumber => editOrderDetail("customerPhoneNumber", newPhoneNumber)}
            comment={order.orderDetails.customerComment}
            editComment={newComment => editOrderDetail("customerComment", newComment)}
        />
    )
}

export default PassengerBlockContainer