import {FC, ReactElement} from "react"
import {ICurtainPanel} from "../../../../LocalUICore/mobile/CurtainPanel"
import DelayedOptionsPanel from "../../../../LocalUICore/mobile/NewOrderPanels/DelayedOptionsPanel"
import {useNewDelivery} from "@kashalot-web/react/dist";

const DelayedOptionsPanelContainer: FC<ICurtainPanel> = (props): ReactElement => {
    const {delivery, setDelayedFeed} = useNewDelivery()

    return (
        <DelayedOptionsPanel
            {...props}
            delayedOptions={delivery.delayed}
            onChangeDelayedOptions={setDelayedFeed}
        />
    )
}

export default DelayedOptionsPanelContainer