import React, {FC, ReactElement, useState} from "react"
import EmployeesPanel from "../../../../LocalUICore/mobile/NewDeliveryPanels/EmployeesPanel"
import {MemberDetailsPanel} from "../../../../LocalUICore/mobile/NewDeliveryPanels/MemberDetailsPanel"
import {useDispatcher, useMap, useNewDelivery, useOrganization, useProfile} from "@kashalot-web/react/dist"
import PhoneNumber from "@kashalot-web/core/dist/Utils/PhoneNumber"
import {makeID} from "@kashalot-web/core/dist/Utils/config"
import EmployeeTypes from "@kashalot-web/core/dist/TaxiInfo/EmployeeTypes"

interface IEmployeesPanelContainer {
    isOpen: boolean,
    open: () => void
    close: () => void
}

const EmployeesPanelContainer: FC<IEmployeesPanelContainer> = ({isOpen, open, close}): ReactElement => {
    const {delivery, removeMember, updateMember} = useNewDelivery()
    const {suggestionsRequests, pushSuggestionRequest} = useMap()
    const {userInfo} = useProfile()
    const {clientsManager} = useDispatcher()
    const {employeesManager} = useOrganization()
    let employees: Array<Employee> = []
    if (userInfo) {
        if (userInfo.dispatcherID) {
            if (delivery.organization && clientsManager) {
                employees = clientsManager.getEmployeesByOrganizationID(delivery.organization.id, true)
            }
        } else {
            employees = employeesManager ? employeesManager.getActiveEmployees() : []
        }
    }

    const makeIDForNewDeliveryMember = (): string => {
        if (userInfo?.dispatcherID) {
            if (employees.length > 0) {
                return employees[Math.floor(Math.random() * employees.length)].id
            }
            return ""
        }
        return userInfo?.userID || ""
    }

    const [employeeIsOpen, setEmployeeIsOpen] = useState<boolean>(false)
    const [targetMember, setTargetMember] = useState<DeliveryMember | null>(null)
    const [targetMemberMode, setTargetMemberMode] = useState<"add" | "edit">("add")
    const handleMemberDetailsChange = (param: "address" | "phone" | "fio" | "amount", value: string) => {
        if (targetMember) {
            switch (param) {
                case "address":
                    setTargetMember({...targetMember, address: value})
                    break
                case "phone":
                    setTargetMember({...targetMember, phone: value})
                    break
                case "fio":
                    setTargetMember({...targetMember, fio: value})
                    break
                case "amount":
                    setTargetMember({...targetMember, amount: Number.parseInt(value)})
                    break
            }
        }
    }
    const validateMember = () => {
        return !!targetMember && !!targetMember.address && PhoneNumber.validateWithMask(targetMember.phone)
    }
    const selectEmployee = (targetEmployee: Employee) => {
        let home: string | null = null
        if (targetEmployee.wishlist) {
            home = targetEmployee.wishlist.find(a => a.type === "home")?.address || null
        }
        if (home) {
            updateMember({
                ...targetEmployee,
                address: home,
                coordinates: null,
                amount: 1,
                key: makeID(10),
                isNew: false
            })
        } else {
            setTargetMember({
                ...targetEmployee,
                address: "",
                coordinates: null,
                amount: 1,
                key: makeID(10),
                isNew: false
            })
            setEmployeeIsOpen(true)
        }
    }

    return (
        <>
            <EmployeesPanel
                open={open} close={close} isOpen={isOpen}
                employees={employees ? employees : []}
                selectEmployee={(employee: Employee) => {
                    selectEmployee(employee)
                    setTargetMemberMode("add")
                }}
                selectedMembers={delivery.members}
                selectMember={(member) => {
                    setTargetMember(member)
                    setTargetMemberMode("edit")
                    setEmployeeIsOpen(true)
                }}
                updateMember={updateMember}
                removeMember={(member: DeliveryMember) => removeMember(member)}
                onClickAdd={() => {
                    setTargetMember({
                        _type: "employee",
                        address: "",
                        coordinates: null,
                        amount: 1,
                        id: makeIDForNewDeliveryMember(),
                        fio: "",
                        email: "",
                        active: true,
                        wishlist: null,
                        phone: "",
                        type: EmployeeTypes.CompanyWorker,
                        isNew: true,
                        key: makeID(10)
                    })
                    setTargetMemberMode("add")
                    setEmployeeIsOpen(true)
                }}
            />
            {targetMember && (
                <MemberDetailsPanel
                    open={() => setEmployeeIsOpen(true)}
                    close={() => {
                        setEmployeeIsOpen(false)
                        if (targetMemberMode === "add") {
                            if (targetMember && validateMember()) {
                                if (targetMember.fio) {
                                    updateMember(targetMember)
                                } else {
                                    updateMember({
                                        ...targetMember,
                                        fio: targetMember.address.split(',').filter((a, i) => i < 2).join(',')
                                    })
                                }
                            }
                        } else if (targetMemberMode === "edit") {
                            const candidate = delivery.members.find(member => !!targetMember && member.id === targetMember.id)
                            if (!candidate || !targetMember
                                || candidate.address !== targetMember.address
                                || candidate.amount !== targetMember.amount
                                || candidate.phone !== targetMember.phone) {
                                updateMember(targetMember)
                            }
                        }
                    }}
                    isOpen={employeeIsOpen}
                    pushSuggestionRequest={(id: string, inputValue: string) => pushSuggestionRequest(id, inputValue)}
                    suggestionsRequest={suggestionsRequests.find(s => s.id === targetMember.id) || null}
                    member={targetMember}
                    changeMemberDetails={handleMemberDetailsChange}
                />
            )}
        </>
    )
}

export default EmployeesPanelContainer