import {FC, ReactElement} from "react"
import {Backdrop, BackdropProps, CircularProgress, Grid, GridProps} from "@mui/material"

const BackdropContainer: FC<GridProps & {open: boolean}> = ({children, open, ...props}): ReactElement => {
    return (
        <Backdrop
            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={open}
        >
            <Grid item container justifyContent="center" alignItems="center" direction="column">
                <CircularProgress color="inherit"/>
                <Grid {...props}>{children}</Grid>
            </Grid>
        </Backdrop>
    )
}

export default BackdropContainer