import {FC, ReactElement} from "react"
import CourierBlock from "../../../../LocalUICore/desktop/NewShippingBlocks/CourierBlock"
import CourierClasses from "@kashalot-web/core/dist/TaxiInfo/CourierClasses"
import {useNewShipping} from "@kashalot-web/react/dist"

const CourierBlockContainer: FC = (): ReactElement => {
    const {shipping, editOrderDetail} = useNewShipping()

    return (
        <CourierBlock
            allCourierClasses={CourierClasses.getAllClasses()}
            selectedCourierClass={shipping.orderDetails.courierClass}
            onCourierClassChange={newCourierClass => editOrderDetail("courierClass", newCourierClass)}

            porters={shipping.orderDetails.porters}
            onAmountPortersChange={newPortersAmount => editOrderDetail('porters', newPortersAmount)}

            autoCourierType={shipping.orderDetails.autoCourierType}
            onChangeAutoCourierType={newAutoCourierType => editOrderDetail('autoCourierType', newAutoCourierType)}

            rentalTime={shipping.orderDetails.rentalTime}
            onRentalTimeChange={newRentalTime => editOrderDetail('rentalTime', newRentalTime)}
        />
    )
}

export default CourierBlockContainer