import {FC, ReactElement} from "react"
import BlockContainer from "../BlockContainer"
import {Typography} from "@mui/material"
import CarClassInput, {ICarClassInput} from "../CarClassInput"
import TimeSelector, {ITimeSelector} from "../TimeSelector"
import DelayedOptionsInput, {IDelayedOptionsInput} from "../DelayedOptionsInput"

interface IOptionsDeliveryBlock {

}

const OptionsDeliveryBlock: FC<IOptionsDeliveryBlock & ICarClassInput & ITimeSelector & IDelayedOptionsInput> = (props): ReactElement => {

    return (
        <>
            <BlockContainer container>
                <Typography variant={"h5"}>Параметры заказа</Typography>
            </BlockContainer>
            <BlockContainer container>
                <CarClassInput {...props}/>
            </BlockContainer>
            <BlockContainer item container>
                <TimeSelector {...props}/>
            </BlockContainer>
            <BlockContainer item container>
                <DelayedOptionsInput {...props}/>
            </BlockContainer>
        </>
    )
}

export default OptionsDeliveryBlock