import {FC, ReactElement} from "react"
import DelayedOptionsPanel from "../../../../LocalUICore/mobile/NewOrderPanels/DelayedOptionsPanel"
import {useNewOrder} from "@kashalot-web/react/dist"
import {ICurtainPanel} from "../../../../LocalUICore/mobile/CurtainPanel"

const DelayedOptionsPanelContainer: FC<ICurtainPanel> = (props): ReactElement => {
    const {order, setDelayedFeed} = useNewOrder()

    return (
        <DelayedOptionsPanel
            {...props}
            delayedOptions={order.offersManager.delayed}
            onChangeDelayedOptions={setDelayedFeed}
        />
    )
}

export default DelayedOptionsPanelContainer