import React, {FC, ReactElement} from "react"
import {Grid, Typography} from "@mui/material"

interface IOrderCardTitle {
    title: string
    status?: string
}

const OrderCardTitle: FC<IOrderCardTitle> = ({title, status}): ReactElement => {
    return (
        <Grid container justifyContent={"space-between"} wrap={"nowrap"} alignItems={"center"}>
            <Typography variant={"h5"}>{title}</Typography>
            {status && <Typography variant={"h5"} align={"right"}>{status}</Typography>}
        </Grid>
    )
}

export default OrderCardTitle