import React, {FC, ReactElement} from "react"
import {Grid, Typography} from "@mui/material"

interface IOrderCardRoute {
    route: Array<String>
}

const OrderCardRoute: FC<IOrderCardRoute> = ({route}): ReactElement => {
    return (
        <Grid item container direction={"column"} wrap={"nowrap"}>
            {(route.length === 2 ? [
                {type: 'first' as 'first', address: route[0]},
                {type: 'last' as 'last', address: route[route.length - 1]}
            ] : [
                {type: 'first' as 'first', address: route[0]},
                {type: 'middle' as 'middle', address: ''},
                {type: 'last' as 'last', address: route[route.length - 1]}
            ]).map((item, ind) => {
                if (item.type === "middle") {
                    return (
                        <Grid
                            key={`address-asdfasf-${ind}`}
                            item container
                            wrap={"nowrap"} alignItems={"center"}
                            sx={{mb: '2px'}}
                        >
                            <Grid item sx={{
                                minWidth: '16px',
                                minHeight: '16px',
                                m: '0 8px',
                                borderRadius: '100px',
                                bgcolor: 'primary.main'
                            }}/>
                            <Typography variant={"subtitle1"}>x{route.length - 2}</Typography>
                        </Grid>
                    )
                }
                return (
                    <Grid
                        key={`address-asdfasf-${ind}`}
                        item container
                        wrap={"nowrap"} alignItems={"center"}
                        sx={{mb: '2px'}}
                    >
                        <Grid item sx={{
                            minWidth: '23px',
                            minHeight: '23px',
                            mr: '8px',
                            borderRadius: '100px',
                            bgcolor: 'primary.main'
                        }}/>
                        <Typography variant={"body2"}>{item.address}</Typography>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default OrderCardRoute