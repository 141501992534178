import {FC, ReactElement} from "react"
import {Grid, Typography, Divider, Fab, Drawer} from "@mui/material"
import ExitToAppIcon from '@mui/icons-material/ExitToApp'

interface ISideBar {
    isOpen: boolean
    open: () => void
    close: () => void
    employee: Employee | null
    logout: () => void
}

const SideBar: FC<ISideBar> = (props): ReactElement => {
    const {
        isOpen, close,
        employee,
        logout
    } = props

    return (
        <Drawer
            anchor="left"
            open={isOpen}
            // onOpen={open}
            onClose={close}
        >
            <Grid
                container
                direction="column"
                wrap="nowrap"
                justifyContent="space-between"
                sx={{height: '100%'}}
            >
                <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    sx={{width: '15rem'}}
                >
                    <Grid
                        item container
                        direction="column"
                        sx={{p: '0.3rem'}}
                    >
                        <Typography variant="body1">
                            Телефон:
                        </Typography>
                        <Typography variant="body1">
                            {employee?.phone || '-'}
                        </Typography>
                    </Grid>
                    <Divider />
                    <Grid
                        item container
                        direction="column"
                        sx={{p: '0.3rem'}}
                    >
                        <Typography variant="body1">
                            Логин:
                        </Typography>
                        <Typography variant="body1">
                            {employee?.email || "-"}
                        </Typography>
                    </Grid>
                    <Divider />
                    <Grid
                        item container
                        direction="column"
                        sx={{p: '0.3rem'}}
                    >
                        <Typography variant="body1">
                            Имя:
                        </Typography>
                        <Typography variant="body1">
                            {employee?.fio || '-'}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    sx={{p: '1rem'}}
                >
                    <Fab color="primary" onClick={logout}>
                        <ExitToAppIcon />
                    </Fab>
                </Grid>
            </Grid>
        </Drawer>
    )
}

export default SideBar