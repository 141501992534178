// @ts-ignore
import {QuickScore} from "quick-score"

const useSearchOrders = (orders: Array<CreatedOrder | CreatedOrdersBatch>, inputSearch: string) => {
    type Item = {
        id: string
        tags: OrderTags
        title: string
        vendorName: string
        carClassTitle: string
        routeStart: string
        routeEnd: string
        statusTitle: string
        paymentTypeTitle: string
        organizationName?: string
        employeeFio?: string
        employeeEmail?: string
    }

    const producedSubOrdersFactory = (subOrder: CreatedOrder, orderKey: string, deliveryKey: string): Item => {
        return {
            id: subOrder.id,
            tags: {
                deliveryKey: deliveryKey,
                orderKey: orderKey,
            },
            title: subOrder.title,
            vendorName: subOrder.vendor.name,
            carClassTitle: subOrder.carClass.title,
            routeStart: subOrder.route[0],
            routeEnd: subOrder.route[subOrder.route.length],
            statusTitle: subOrder.status.title,
            paymentTypeTitle: subOrder.paymentType.title,
            organizationName: subOrder.organization?.name || undefined,
            employeeFio: subOrder.employee?.fio || undefined,
            employeeEmail: subOrder.employee?.email || undefined,
        }
    }

    const items: Array<Item> = []
    orders.forEach(order => {
        if (order._type === "created_order") {
            items.push(producedSubOrdersFactory(order, order.tags.orderKey, ""))
        } else if (order._type === "created_orders_batch") {
            const targetDelivery = order
            targetDelivery.orders.forEach((order) => {
                items.push(producedSubOrdersFactory(order, order.tags.orderKey, targetDelivery.tags.deliveryKey))
            })
            return {
                deliveryKey: order.tags.deliveryKey
            }
        }
    })

    const qsOrders = new QuickScore(items, [
        "title",
        "vendorName",
        "carClassTitle",
        "routeStart",
        "routeEnd",
        "statusTitle",
        "paymentTypeTitle",
        "organizationName",
        "employeeFio",
        "employeeEmail",
    ])
    let filteredOrders = orders
    if (inputSearch !== "") {
        filteredOrders = []
        qsOrders.search(inputSearch)
            .map((res: any) => res.item)
            .forEach((item: Item) => {
                const order = orders.find((order) => {
                    if (item.tags.deliveryKey) {
                        return order.tags.deliveryKey === item.tags.deliveryKey
                    } else if (item.tags.orderKey) {
                        return order.tags.orderKey === item.tags.orderKey
                    } else {
                        const targetOrder: CreatedOrder = order as CreatedOrder
                        return targetOrder.id === item.id
                    }
                })
                if (order) {
                    filteredOrders.push(order)
                }
            })
    }

    return filteredOrders
}

export default useSearchOrders