import {FC, ReactElement} from "react"
import {CircularProgress, Grid, Typography} from "@mui/material"
import BlockContainer from "../BlockContainer"
import OfferCard from "../OfferCards/OfferCard"
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi'

interface IOffersBlock {
    order: IOrder | IShippingOrder
    offers: Array<IOffer>
    selectedOffers?: Array<IOffer>
    selectOffer: (offer: IOffer) => void
    loading: boolean
    showTitle?: boolean
    showBGColor?: boolean
    success?: boolean
    ordering?: boolean
}

const OffersBlock: FC<IOffersBlock> = (props): ReactElement => {
    const {
        order,
        offers, selectedOffers, selectOffer,
        loading,
        showTitle, showBGColor,
        success, ordering
    } = props

    if (ordering === true) {
        return (
            <BlockContainer
                item container justifyContent={"center"}
                sx={{bgcolor: showBGColor === false ? '' : 'primary.light'}}
            >
                <CircularProgress/>
            </BlockContainer>
        )
    }

    return (
        <>
            {showTitle !== false && (
                <BlockContainer item container sx={{bgcolor: showBGColor === false ? '' : 'primary.light'}}>
                    <Typography variant={"h5"}>Подбор таксопарка</Typography>
                </BlockContainer>
            )}
            {success && (
                <BlockContainer
                    item container justifyContent={"center"}
                    sx={{bgcolor: showBGColor === false ? '' : 'primary.light'}}
                >
                    <Grid item>
                        <Grid container direction={"column"} wrap={"nowrap"} alignItems={"center"}>
                            <Typography color={"success"}>
                                Заказ успешно создан
                            </Typography>
                        </Grid>
                    </Grid>
                </BlockContainer>
            )}
            {!success && loading && offers.length === 0 && (
                <BlockContainer
                    item container justifyContent={"center"}
                    sx={{bgcolor: showBGColor === false ? '' : 'primary.light'}}
                >
                    <CircularProgress/>
                </BlockContainer>
            )}
            {!success && offers.length > 0 && (
                <BlockContainer item container sx={{bgcolor: showBGColor === false ? '' : 'primary.light'}} spacing={2}>
                    {offers.map((offer, ind) => {
                        if (selectedOffers) {
                            return (
                                <Grid item key={`offer-asdfadf-ind${ind}`}>
                                    <OfferCard
                                        order={order}
                                        offer={offer}
                                        onClick={() => selectOffer(offer)}
                                    />
                                </Grid>
                            )
                        }
                        return (
                            <Grid item key={`offer-asdfadf-ind${ind}`}>
                                <OfferCard
                                    order={order}
                                    offer={offer}
                                    onClick={() => selectOffer(offer)}
                                />
                            </Grid>
                        )
                    })}
                </BlockContainer>
            )}
            {!success && !loading && offers.length === 0 && (
                <BlockContainer
                    item container justifyContent={"center"}
                    sx={{bgcolor: showBGColor === false ? '' : 'primary.light'}}
                >
                    <Grid item>
                        <Grid container direction={"column"} wrap={"nowrap"} alignItems={"center"}>
                            <LocalTaxiIcon/>
                            <Typography color={"gray"}>
                                {(() => {
                                    let routeReady = false
                                    if (order._type === "new_order") {
                                        routeReady = order.routeManager.routeReady()
                                    } else {
                                        const tmp = order.orderDetails.members.reduce((acc, member) =>  !!member.point.address && acc, true)
                                        routeReady = tmp
                                    }
                                    if (routeReady) {
                                        return 'Попробуйте выбрать другой класс автомобиля'
                                    }
                                    return 'Введите маршрут'
                                })()}
                            </Typography>
                        </Grid>
                    </Grid>
                </BlockContainer>
            )}
        </>
    )
}

export default OffersBlock