import React, {FC, ReactElement} from "react"
import {Avatar, Grid, Typography} from "@mui/material"

interface IOrderCardCoprInfo {
    price: number
    minPrice?: number
    maxPrice?: number
    paymentType: PaymentType
    customerName: string
    carClass: TaxiClass
    maxChars?: number
}

const OrderCardCoprInfo: FC<IOrderCardCoprInfo> = (props): ReactElement => {
    const {price, minPrice, maxPrice, paymentType, customerName, maxChars, carClass} = props

    const nameAvatar = customerName.split(' ').slice(0, 2).map(n => n[0]).join('').toUpperCase()

    return (
        <Grid container direction={"column"} wrap={"nowrap"}>
            <Grid item container justifyContent={"space-between"} wrap={"nowrap"} alignItems={"center"}>
                <Grid item><Typography variant={"h5"}>{paymentType.title}</Typography></Grid>
                <Grid item><Typography variant={"h5"}>{carClass.title}</Typography></Grid>
            </Grid>
            <Grid item container justifyContent={"space-between"} wrap={"nowrap"} alignItems={"center"} sx={{pt: '5px'}}>
                <Grid item>
                    <Grid container wrap={"nowrap"} alignItems={"center"}>
                        {nameAvatar && (<Avatar>{nameAvatar}</Avatar>)}
                        <Typography variant={"body2"} sx={{ml: '5px'}}>
                            {customerName.slice(0, maxChars || 25) + (customerName.length >= (maxChars || 25) ? '...' : '')}
                        </Typography>
                    </Grid>
                </Grid>
                {minPrice !== undefined && maxPrice !== undefined ? (
                    <Grid item><Typography variant={"h5"}>{minPrice}₽ - {maxPrice}₽</Typography></Grid>
                ) : (
                    <Grid item><Typography variant={"h5"}>{price}₽</Typography></Grid>
                )}
            </Grid>
        </Grid>
    )
}

export default OrderCardCoprInfo