import React, {FC, ReactElement} from "react"
import {Grid, Typography} from "@mui/material"

interface IAddressesSuggestions {
    activeAddressInput: boolean
    suggestionsRequest: SuggestionsRequest | null
    setDestination: (address: string) => void
}
const AddressesSuggestions: FC<IAddressesSuggestions> = (props): ReactElement => {
    const {activeAddressInput, suggestionsRequest, setDestination}: IAddressesSuggestions = props

    return (
        <>
            {activeAddressInput || (
                <Grid item>
                    <Typography
                        variant="body2"
                        sx={{padding: '10px'}}
                    >
                        Начните вводить адрес
                    </Typography>
                </Grid>
            )}
            {activeAddressInput && suggestionsRequest && suggestionsRequest.suggestionsList.length > 0 && (
                suggestionsRequest.suggestionsList
                    .map((suggest: { title: string, value: string }, index: number) => {
                        return (
                            <Grid
                                item key={`create-order-suggest-${index}`}
                                onClick={() => setDestination(suggest.title)}
                            >
                                <Typography
                                    variant="body2"
                                    sx={{padding: '10px'}}
                                >
                                    {suggest.title}
                                </Typography>
                            </Grid>
                        )
                    })
            )}
            {activeAddressInput && suggestionsRequest && suggestionsRequest.suggestionsList.length === 0 && (
                <Grid item>
                    <Typography
                        variant="body2"
                        sx={{padding: '10px'}}
                    >
                        Ничего не найдено
                    </Typography>
                </Grid>
            )}
        </>
    )
}

export default AddressesSuggestions