import React, {FC, ReactElement, useState} from "react"
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    TextField
} from "@mui/material"
import AdapterMoment from "@mui/lab/AdapterMoment"
import {DesktopDateTimePicker, LocalizationProvider, MobileDateTimePicker} from "@mui/lab"
import EventIcon from '@mui/icons-material/Event'

export interface ITimeSelector {
    isUrgent: boolean
    onUrgencyChange: (newValue: boolean) => void
    deadline: any
    onDeadlineChange: (newTime: any) => void
}

const TimeSelector: FC<ITimeSelector> = ({isUrgent, onUrgencyChange, deadline, onDeadlineChange}): ReactElement => {
    const [isOpenPicker, setIsOpenPicker] = useState<boolean>(false)
    const openPicker = () => setIsOpenPicker(true)
    const closePicker = () => setIsOpenPicker(false)

    return (
        <Grid container>
            <Grid item sx={{mr: '24px'}}>
                <FormControl>
                    <FormLabel id="urgency-radio-selector">Время подачи</FormLabel>
                    <RadioGroup
                        aria-labelledby="urgency-radio-selector"
                        value={isUrgent}
                        onChange={e => onUrgencyChange(e.target.value === "true")}
                    >
                        <FormControlLabel value={false} control={<Radio />} label="Ко времени" />
                        <FormControlLabel value={true} control={<Radio />} label="На ближайшее время" />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <MobileDateTimePicker
                        renderInput={(props) => <></>}
                        ampm={false}
                        disablePast
                        value={deadline}
                        onChange={(newDate: any) => onDeadlineChange(newDate)}
                        open={isOpenPicker}
                        onClose={closePicker}
                    />
                    <DesktopDateTimePicker
                        label="Дата подачи"
                        value={deadline}
                        onChange={(newValue) => onDeadlineChange(newValue)}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                disabled={isUrgent}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton size={"large"} onClick={openPicker} disabled={isUrgent}>
                                            <EventIcon />
                                        </IconButton>
                                    )
                                }}
                            />}
                    />
                </LocalizationProvider>
            </Grid>
        </Grid>
    )
}

export default TimeSelector