export default (order: CreatedOrder) => {
    if (navigator.share) {
        navigator.share({
            title: order.title,
            text: '',
            url: 'https://kashalot.me/order/' + order.link,
        })
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing', error))
    } else {
        console.log('Share not supported on this browser, do it the old way.')
    }
}