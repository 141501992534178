import React, {FC, ReactElement} from "react"
import {Divider, Grid, Paper} from "@mui/material"
import OrderCardCover from "./OrderCardCover"
import moment from "moment"
import OrderCardTitle from "./OrderCardTitle"
import Statuses from '@kashalot-web/core/dist/TaxiInfo/Statuses'
import OrderCardRoute from "./OrderCardRoute"
import OrderCardTaxiInfo from "./OrderCardTaxiInfo"
import OrderCardCoprInfo from "./OrderCardCoprInfo"
import OrderCardOptions from "./OrderCardOptions"

interface IOrderCard {
    order: CreatedOrder
    replay?: () => void
    share?: () => void
    cancel?: () => void
    type: 'full' | 'short'
    actor: "client" | "dispatcher"
}

const getJamsTime = (order: CreatedOrder): number => {
    let jamsTime: number = 0
    if (order.auto && order.auto.jamsTime) {
        jamsTime = Math.floor(order.auto.jamsTime / 60)
    } else {
        const minutes = Math.floor((order.auto?.jamsTime ||
            moment.duration(moment(order.deadline, "HH:mm DD.MM.YYYY").diff(moment())).asSeconds()) / 60)
        if (minutes > 0) {
            jamsTime = minutes
        }
    }
    return jamsTime
}

const OrderCard: FC<IOrderCard> = (props): ReactElement => {
    const {
        order, replay, share, cancel,
        type, actor
    } = props

    return (
        <Paper
            component={Grid}
            container
            direction={"column"}
            wrap={"nowrap"}
            sx={{overflow: "hidden"}}
        >
            <OrderCardCover
                deadline={moment(order.deadline, "HH:mm DD.MM.YYYY").format("DD MMMM HH:mm")}
                bgcolor={[...Statuses.cancelingStatuses(), ...Statuses.completedStatuses()]
                    .map(s => s.id).includes(order.status.id) ? 'primary.dark' : 'primary.main'}
                replay={replay}
                share={share}
                cancel={cancel}
            />
            <Grid
                item container direction={"column"} wrap={"nowrap"}
                sx={{p: '5px 16px', bgcolor: type === 'short' ? 'primary.light' : ''}}
            >
                {[
                    <OrderCardTitle
                        title={order.title}
                        status={order.status.id === Statuses.Status8.id ? (
                            `Через ~ ${getJamsTime(order)} мин`
                        ) : (
                            order.status.title
                        )}
                    />,
                    <OrderCardRoute route={order.route} />,
                    <Divider sx={{width: '100%'}}/>,
                    <OrderCardTaxiInfo status={order.status} auto={order.auto} vendor={order.vendor} />,
                    <Divider sx={{width: '100%'}}/>,
                    (order.options.length > 0 ? <OrderCardOptions options={order.options} /> : null),
                    (order.options.length > 0 ? <Divider sx={{width: '100%'}}/> : null),
                    <OrderCardCoprInfo
                        carClass={order.carClass}
                        price={order.price}
                        paymentType={order.paymentType}
                        customerName={actor === "dispatcher"
                            ? order.organization?.name || ""
                            : order.employee?.fio || order.employee?.email || ""
                        }
                    />
                ].map((body, ind) => {
                    if (body) {
                        return (
                            <Grid item container sx={{m: '5px 0'}} key={`container-ind-asdfadf-${ind}`}>{body}</Grid>
                        )
                    }
                })}
            </Grid>
        </Paper>
    )
}

export default OrderCard