import {FC, ReactElement} from "react"
import AutoBlock from "../../../../LocalUICore/desktop/NewOrderBlocks/AutoBlock"
import {useNewOrder} from "@kashalot-web/react/dist"
import TaxiClasses from '@kashalot-web/core/dist/TaxiInfo/TaxiClasses'

const AutoBlockContainer: FC = (): ReactElement => {
    const {order, editOrderDetail, setOptions, setDelayedFeed} = useNewOrder()

    return (
        <AutoBlock
            selectedOptions={order.offersManager.options}
            onOptionsChange={newOptions => setOptions(newOptions)}

            allTaxiClasses={TaxiClasses.getAllClasses()}
            selectedTaxiClass={order.orderDetails.carClass}
            onTaxiClassChange={newTaxiClass => editOrderDetail("carClass", newTaxiClass)}

            isUrgent={order.orderDetails.isUrgent}
            onUrgencyChange={newValue => editOrderDetail("isUrgent", newValue)}
            deadline={order.orderDetails.deadline}
            onDeadlineChange={newDeadline => editOrderDetail("deadline", newDeadline)}

            delayedOptions={order.offersManager.delayed}
            onChangeDelayedOptions={setDelayedFeed}
        />
    )
}

export default AutoBlockContainer